<template>
    <div class="home">
        <section>
            <Homebanner/>
        </section>

        <section class="py-5">
            <div class="container">
                <Heading heading="About Company"
                         headingdesc="Planning on building a large scale, custom-made and high-performing software solution for your business? Look no further as we at Xeoscript Technologies should be able to do the job for you. "
                         headingsubdesc="We are a successful team of experts with immense industry experience in the realm of  web and mobile application and other software solutions. Our squad of developers are competent in ways to boost, reciprocate and execute the business and lead you to the path of success."/>
                <div class="row">
                    <div class="col-12 col-lg-3 mb-3 mb-lg-0">
                        <Component9 heading="Our Services"/>
                    </div>
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-lg-4 mb-3">
                                <Component1 link="/web-design-thrissur-kerala/" img="website.png" heading="Website Designing"
                                            desc="With our team of web designers, you will have no problems in establishing your company’s brand and attracting customers through your website."/>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <Component1 link="/mobile-application-development-thrissur-kerala/" img="mobile app.png" heading="Mobile application"
                                            desc="Our expert mobile app developers will assist you in taking your business into new heights through our excellent Android and  iOS application."/>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <Component1 link="/web-app-thrissur-kerala/" img="web-app.png" heading="Web Application"
                                            desc="All the latest technologies used by our team gives us an upper hand in providing our clients tailor-made web apps to suit their needs."/>
                            </div>
                            <div class="col-lg-4 mb-3 mb-lg-0">
                                <Component1 link="/software-development-thrissur-kerala/" img="software.png" heading="Software Development"
                                            desc="Our team is capable of developing software solutions from the simplest to the most complex ones, depending on the client requirement."/>
                            </div>
                            <div class="col-lg-4 mb-3 mb-lg-0">
                                <Component1 link="/e-commerce-thrissur-kerala/" img="ecommerce.png" heading="E commerce Application"
                                            desc="Our E-commerce projects are sure to  increase leads, convert browsers into buyers, drive traffic your way and lead your business to profit."/>
                            </div>
                            <div class="col-lg-4 mb-3 mb-lg-0">
                                <Component1 link="/digital-marketing-thrissur-kerala/" img="digital.png" heading="Digital Marketing"
                                            desc="We will work a way out and come up with strategies for you to maximize and successfully reach and convert new customers."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5 bg-gray-1">
            <div class="container">
                <heading heading="Our Latest Works" headingdesc="Take a look at our recent accomplished projects."/>

                <Service3/>

                <div class="row fl-x-cc mt-4">
                    <div class="col-8 col-lg-3">
                        <router-link to="/our-works/" class="lego-btn xeo-btn-2 btn-block">View All Works</router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="pb-5 bg-gray-1">
            <div class="container">
                <ClientCarousel/>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Our client's words"
                         headingdesc="We have been able to achieve and carve some great works in the past for our clients and have always developed and delivered  to help them stay way ahead in the lead."/>
                <ClientWordsCarousel/>
            </div>
        </section>

        <section class="pb-5">
            <Counter/>
        </section>

        <section class="pb-5 d-none">
            <div class="container">
                <heading heading="News & Blogs"/>
                <div class="fl-x-cc fl--lg-br mb-4 row">
                    <div class="col-6 col-lg-3">
                        <button class="lego-btn xeo-btn-2 btn-block">Read All Blogs</button>
                    </div>
                </div>
                <!--                <Service4/>-->
            </div>
        </section>

        <section>
            <GetAQuote/>
        </section>

    </div>
</template>

<script>

import Homebanner from './components/Homebanner';
import Heading from './components/Heading';
import Component9 from './components/single-components/Component-9';
import Component1 from './components/single-components/HomeServiceComponent';
import ClientCarousel from './components/Client-carousel';
import ClientWordsCarousel from './components/Client-words-carousel';
import Counter from './components/Counter';
// import Service4 from './components/Service-4';
import GetAQuote from './components/GetAQuote';
import Service3 from './components/Service-3';

export default {
    metaInfo: {
        title: 'Mobile App and Web development Company in Thrissur, Kerala',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Planning on building a large scale, custom-made and high-performing software solution for your business? Look no further as we at Xeoscript Technologies should be able to do the job for you.'
            },
            {
                name: 'keywords',
                content: 'software company in thrissur, it companies in thrissur, web designing company in thrissur, mobile app development company in thrissur,digital marketing services in thrissur'
            }
        ]
    },
    name: 'Home',
    components: {
        Service3,
        GetAQuote,
        // Service4,
        Counter,
        ClientWordsCarousel,
        ClientCarousel,
        Component1,
        Component9,
        Heading,
        Homebanner
    }
};

</script>
