<template>
    <div class="our-works-bg">
        <div class="fl-x our-works container ">
            <div class="row m-lg-0">
                <div class="img-screen col-12 col-lg-6 fl-eq-c py-4">
                    <img :src="require('@/assets/images/our-works/' + img)" alt="">
                </div>
                <div class="img-container col-12 col-lg-6 fl-eq-c py-4">
                    <div>
                        <img :src="require('@/assets/images/clients/' + logo)" class="w-logo" alt="">
                        <p class="mt-3 mb-3 fs-3 fs-lg-6" v-if="heading || heading1">
                            <span :class="'text-' + heading1Color" v-if="heading1" v-html="heading1"
                                  class="font-weight-bold">Easy Win </span>
                            <span v-if="heading1"> - </span> {{ heading }}
                        </p>
                        <p v-if="desc" v-html="desc"></p>
                        <img src="@/assets/images/our-works/play-store.png" class="mt-3 img-play-store" alt="">
                        <div class="fl-x-t img-icons mt-4 fs--1">
                            <p class="fl-x-c ">
                                <img src="@/assets/images/our-works/platform.png" alt="" class="pr-2">
                                Android
                            </p>
                            <span class="divider"></span>
                            <p class="fl-x-c pl-3">
                                <img src="@/assets/images/our-works/company.png" alt="" class="pr-2">
                                EasyWin
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurWorkComponent3',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        heading1: {
            type: String,
            required: false,
            default: ''
        },
        heading1Color: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        },
        img: {
            type: String,
            required: false,
            default: ''
        },
        logo: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>
