<template>
    <div class="fl-x-tc about-us bg-fit-c py-4 p-lg-6 overflow-h">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 text-center">
            <h1 v-if="heading" v-html="heading" class="mb-4 underline u-primary font-weight-bold fs-9" data-aos="fade-left" data-aos-duration="500">About Our Company</h1>
            <h2 v-if="desc" v-html="desc" class="text-color m-0 fs-1" data-aos="fade-left" data-aos-duration="500">Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Molestias excepturi nihil
                dolor earum minus eo Lorem ipsum dolor sit, amet consectetur adipisicing elit. ?</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">
.about-us {
    background-image: url('../../assets/images/about-us/banner bgi.jpg');
    h2{
        line-height: 24px;
    }
}

.text-color {
    color: gray;
}
</style>
