<template>
    <div class="our-works-bg">
        <div class="our-works container">
            <div class="row">
                <div class="img-screen col-12 col-lg-6 fl-eq-c py-4">
                    <img :src="require('@/assets/images/our-works/' + img)" class="round-3" alt="">
                </div>
                <div class="img-container col-12 col-lg-6 fl-eq-c py-4" :class="'order-lg-' + align">
                    <div>
                        <img :src="require('@/assets/images/clients/' + logo)" class="w-logo" alt="">
                        <p class="mt-3 mb-3 fs-3 fs-lg-6" v-if="heading || heading1">
                            <span :class="'text-' + heading1Color" v-if="heading1" v-html="heading1"
                                  class="font-weight-bold">Easy Win </span>
                            <span v-if="heading1"> - </span> {{ heading }}
                        </p>
                        <p class="mt-3" v-if="desc" v-html="desc"></p>
                        <div class="fl-x-t img-icons mt-5">
                            <p class="fl-x-c ">
                                <img src="@/assets/images/our-works/platform.png" alt="" class="pr-2">
                                Responsive Design
                            </p>
                            <span class="divider"></span>
                            <p class="fl-x-c pl-3 ">
                                <img src="@/assets/images/our-works/company.png" alt="" class="pr-2">
                                Federal Bank
                            </p>
                            <span class="divider d-none d-lg-block"></span>
                            <p class="fl-x-c pl-3 d-none d-lg-flex">
                                <img src="@/assets/images/our-works/website.png" alt="" class="pr-2">
                                View Website
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurWorkComponent4',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        heading1: {
            type: String,
            required: false,
            default: ''
        },
        align: {
            type: String,
            required: false,
            default: ''
        },
        heading1Color: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        },
        img: {
            type: String,
            required: false,
            default: ''
        },
        logo: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">

.our-works-bg {
    background-color: #c9e7ff;
}

@media (min-width: 768px) {

    .our-works-bg {
        .our-works {
            .img-container {
                background: #c9e7ff;
            }
        }
    }
}
</style>
