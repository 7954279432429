<template>
    <div class="container-fluid  mt-3">
        <div class="b-1 b-gray p-4">
            <div class="fl-x">
                <img src="@/assets/images/client-words/users.jpg" alt="profile" class="profile-img">
                <div class="pl-3">
                    <p class="font-weight-bold fs-2 mb-1" v-if="name" v-html="name"></p>
                    <p class="font-weight-bold text-gray mb-0" v-if="desg" v-html="desg"></p>
                </div>
            </div>
            <div class="pt-3">
                <p v-if="date" v-html="date" class="text-gray mb-1"></p>
                <p class="font-weight-bold fs-1 mb-2">Best Web Design Company </p>
                <p class="text-gray fs--1 mb-0">Lorem ipsum dolor sit amet consectetur harum illo deseruntLorem ipsum
                    dolor sit amet consectetur adipisicing elit. Culpa dolorem reiciendis, harum illo deserun</p>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-2',
    props: {
        name: {
            type: String,
            required: false,
            default: ''
        },
        desg: {
            type: String,
            required: false,
            default: ''
        },
        date: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

.profile-img {
    width: 60px;
    border-radius: 50%;
}

</style>
