<template>
    <div class="overflow-h">
        <div class="row">
            <div class="col-12 col-lg-3 p-xs-0">
                <div class="bs-4 bg-card bg-fit-c fl-x-cc h-100 p-4" data-aos="flip-up" data-aos-duration="1000">
                    <div class="text-white">
                        <p class="be-vietnam text-3x text-left mb-0">"</p>
                        <p class="m-0 mx-3 fs-8 font-weight-bold text-center text-lg-left">
                            Giving Wings to Your Dreams
                        </p>
                        <p class="be-vietnam text-3x mb-0 rotate">"</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-9 p-xs-0">
                <div class="b-1 b-gray h-100 b-xs-0" data-aos="slide-up" data-aos-duration="1000">
                    <div class="bt-5 b-primary center-justified p-4 p-lg-5 h-100 fl-eqh-c b-xs-0">
                        <p class="fs-7">Who We Are</p>
                        <p class="mb-0 fs--1 underline u-primary">
                            Xeoscript Technologies, established in 2010, as a partnership firm was migrated to Perfruor Enterprises Pvt. Ltd. in 2018. Xeoscript is a company that
                            is recognized both nationally and internationally in the software, mobile, and web application development province. Our team of around 30 to 40 divided
                            into various departments has undergone thorough hands-on experience with emerging technologies and expert training to assist our clients in their
                            success stories. We can assure you of delivering accurate and meticulous data as we understand how important this is to you and your business.
                            <!--                            We are a company that is recognized both nationally as well as internationally in the-->
                            <!--                            software, mobile, and web application development province. The one thing that we can assure-->
                            <!--                            you of is delivering accurate and meticulous data as we understand how important this is to-->
                            <!--                            you and your business. We render our best shot to harness the perfect output. Our team has-->
                            <!--                            undergone thorough hands-on experience with emerging technologies and expert training to-->
                            <!--                            assist our clients in their success stories.-->
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-lg-9 p-xs-0">
                <div class="b-1 b-gray p-4 p-lg-5 h-100 b-xs-0" data-aos="slide-up" data-aos-duration="1000">
                    <div class="row fl-a-d h-100">
                        <div class="col-12 col-lg-4 about-service mb-4">
                            <p class="fs-5 fs-lg-9 font-weight-bold underline-l u-primary text-uppercase">What <br
                                class="d-xs-n"> Do We
                                <br class="d-xs-n"> Offer?</p>
                        </div>
                        <div class="col-12 col-lg-8">
                            <p class="text-justify m-0">
                                We offer an inclined level of certainty and satisfaction, when it comes to delivering IT
                                solutions, be it mobile app services, web app services, or services of any kind at all.
                                We ensure solutions that will help clients achieve a higher level of a prospectus in
                                regards to their core business and also make sure that the client always leaves happy
                                and joyful. Our talent pool is efficient enough to come with a solution that will meet
                                the distinct needs of your business.

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-3">
                <div class="b-1 b-gray py-5 px-4 vision h-100 position-relative overflow-h" data-aos="flip-down"
                     data-aos-duration="1000">
                    <div>
                        <p class="fs-3"><span class="font-weight-bold text-justify fs-7">W</span>e make sure to mark our
                            presence
                            across the globe by giving fast, reliable and prompt services without thinking of location as a barrier.</p>
<!--                        <p class="font-weight-bold fs-3 mb-0">-->
<!--                            -->
<!--                        </p>-->
                    </div>
                    <div class="bottom-angle"></div>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12">
                <heading heading="CEO's Message"/>
            </div>
            <div class="row b-2 p-5">
                <div class="col-12 col-lg-4 fl-x-c mb-4 mb-lg-0" data-aos="flip-left" data-aos-duration="1000">
                    <div>
                        <img src="../../assets/images/CEO.jpg" class="img-fluid" alt="">
                        <div class="text-center mt-3">
                            <p class="font-weight-bold mb-1">Muhammed K K, CEO</p>
                            <p class="mb-0">Xeoscript Technologies</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-8 text-justify" data-aos="slide-up" data-aos-duration="1000">
                    <div class="h-100">
                        <p class="fs-4 font-weight-bold text-center text-lg-left">Make Things Easy</p>
                        <p> "<span class="font-weight-bold"> Xeoscript Technologies</span>" is a company that ensures the future is strong through software
                            development, specialized in Mobile App and Website Development.</p>
                        <p>
                            We have the latest technical skills/experiences and excellent human resources. Our company
                            creates what customers need thereby transforming businesses through the power of technology.
                            Passion of technology and Innovation is the spark that ignites every one of us. It fuels our
                            desire to help organizations evolve and grow so they can help promising new opportunities.
                            We're constantly using that passion to dream up new ideas, help our clients create their IT
                            visions for the digital-era- and fun along the way. These are our motivations.
                        </p>
                        <p>Moreover, we provide high-quality and value-added software services to our
                            customers and societies as our "higlighted skills" and aim for global contributions.</p>
                        <p>We will Continue providing the best performance for bringing innovation and inspiring young
                            Minds through responsible, friendly and enjoyable work space.</p>
                        <p class="mb-0">We would like to have your support and patronage and look forward to doing
                            businesses with
                            you.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Heading from './Heading';

export default {
    name: 'About-desc',
    components: { Heading }
};
</script>

<style scoped lang="scss">

.bottom-angle {
    position: absolute;
    background: var(--color-primary);
    height: 100px;
    right: -22px;
    bottom: -42px;
    width: 50px;
    transform: rotate(45deg);
}

.overflow-h {
    overflow: hidden;
}

.center-justified {
    text-align: justify;
    text-align-last: center;
}

.rotate {
    transform: rotate(180deg);
}

.about-service {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.underline-l {
    position: relative;
}

.underline-l::after {
    content: "";
    position: absolute;
    bottom: -20px;
    height: 2px;
    width: 30px;
    left: 0;
    transform: translate(0, 0);
}

.vision {
    //word-break: break-all;
    text-align: justify;
}

@media (max-width: 992px) {

    .about-service {
        flex-direction: row;
    }

    .underline-l::after {
        content: "";
        position: absolute;
        bottom: -20px;
        height: 2px;
        width: 30px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .b-xs-0 {
        border: none;
    }

    .d-xs-n {
        display: none;
    }

    .p-xs-0 {
        padding: 0;
    }
}
</style>
