<template>
  <div class="fl-x-tc quote bg-fit-c py-5 overflow-h" data-aos="zoom-out" data-aos-duration="1000">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center ">
      <h3 class="mb-4 font-weight-bold" v-if="heading" v-html="heading"></h3>
      <p class="description" v-if="desc" v-html="desc"></p>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Quote',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

.description {
  color: #9c9c9c;
}

.quote h3 {
  color: ivory;
}

.quote {
  background-image: url('../../assets/images/bgi3.jpg');
}

.quote h3 {
  quotes: "“" "”" "‘" "’";
}

.quote h3::before {
  content: open-quote;
  font-size: 1.5em;
  font-weight: normal;
}

.quote h3::after {
  content: close-quote;
  font-size: 1.5em;
  font-weight: normal;
}
</style>
