<template>
    <div class="web-app">
        <section>
            <About heading="Your trusted social media marketing company in Kerala"
                   desc="Looking for a reputable company to develop the right social strategies for your company? Count on Xeoscript Technologies, the pioneers in social media marketing in Thrissur."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Why social media marketing?</h3>
                        <p class="mb-0">The 21st-century consumer makes use of digital technologies to access
                            products and services. It is important to build strong relationships with them and create a
                            community of trusted users. Here’s how social media marketing will benefit your
                            organization.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="smm/1.png" heading="Creates a strong brand presence"
                                             desc="Your brand says a lot about you and your organization. By marketing yourself well, you can increase brand recognition and generate potential leads and sales."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="smm/2.png" heading="Boosts search engine rankings"
                                             desc="By regularly posting SEO-optimised content, you reach a new set of audience, and of course, getting ranked higher than your competitors."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="smm/3.png" heading="Gives you customer insights"
                                             desc="Don't you want to know what your customers like and dislike? Observe this and many other trends by making use of the consumer insights offered on networking platforms."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/smm/img.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="smm/4.png" heading="Improves site traffic"
                                             desc="Social media is one of the powerful tools to leverage inbound traffic to your website. One of the best inbound marketing strategies would be establishing your brand on social media."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="smm/5.png" heading="Helps in connecting with your customers"
                                             desc="Customer satisfaction is one of the important indicators of the success of any company. By using networking platforms to reply to queries and resolve issues, you can build customer loyalty. "/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="smm/6.png" heading="Showcase your new products/services better"
                                             desc="Want to create a buzz amongst your audiences with new product launches? Build up the hype on networking platforms and see the difference in your sales!"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="Helping clients with SMM since 2014"
                   desc="According to statistics, the number of people on social media platforms has tripled from the last decade. As of 2020, there are over 3.81 billion users! As far as India is concerned, there is a lot of sales generation happening via social media today. Our digital marketing team understands how important each user is and can help you reach out to them. So, call us today for a free consultation!"/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Rely on our expert team for"/>
                <div class="row">
                    <div class="col-12 col-lg-6 fl-x-cc">
                        <ul class="bullet-1 text-gray" data-aos="fade-right" data-aos-duration="500">
                            <li>Brand exposure on various networking platforms</li>
                            <li>Carrying out market researches to understand your audiences</li>
                            <li>Creating social media marketing strategies that are right for your company</li>
                            <li>Finding the right hashtags to make your brand sell</li>
                            <li>Developing engaging social media adverts</li>
                            <li>Engaging with customers online</li>
                        </ul>
                    </div>
                    <div class="col-12 col-lg-6 fl-x-cc order-first order-lg-0 mb-4 mb-lg-0" data-aos="fade-left" data-aos-duration="500">
                        <img src="../../assets/images/service-page/smm/bgi.png" alt="#" class="img-fluid">
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Why are we a unique social media marketing agency in Kerala?"
                         headingdesc="When you count on us, we can help you with everything from showcasing your brand in the best possible way to improving your conversion rates."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="smm/why-1.jpg" heading="Content curation"
                                    desc="Whether you need regular blog posts about the courses you offer or need cool Instagram captions for a launch, count on us for amazing content."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="smm/why-2.jpg" heading="Tracking and analytics"
                                    desc="We have the right tools to evaluate your profiles and improve your visibility. These are handled by our social media marketing agency in Kerala."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="smm/why-3.jpg" heading="Advertisement development"
                                    desc="If you’re looking for a social media marketing company in India to help you create strong brand positioning, count on Xeoscript Technologies."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="smm/why-4.jpg" heading="Influencer marketing"
                                    desc="One of the effective ways to market your brand is through influencers with dedicated following. We can help you partner with suitable social stars."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="smm/why-5.jpg" heading="Managing social media"
                                    desc="Thinking of creating an Instagram reel about your latest venture? We can market your brand tastefully on Instagram and other platforms."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="smm/why-6.jpg" heading="Top-quality website traffic"
                                    desc="Our team can increase the quantity and quality of traffic on your website to give you better conversion rates, potential leads, and increased sales."/>
                    </div>

                </div>
            </div>
        </section>

        <section>
            <GetAQuote/>
        </section>

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
import GetAQuote from '../components/GetAQuote';
import Component10 from '../components/single-components/Component-10';

export default {
    name: 'SMM',
    metaInfo: {
        title: 'Expert Digital Marketing Services To Grow Your Business',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript technology is a professional Digital marketing company in Thrissur - Kerala. We provide valued digital marketing services to our customers'
            },
            {
                name: 'keywords',
                content: 'digital marketing companies in thrissur, digital marketing in thrissur, social media marketing services in thrissur, internet marketing companies in thrissur, SEO companies in thrissur, SEO in thrissur'
            }
        ]
    },
    components: { Component10, GetAQuote, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>
