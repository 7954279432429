<template>
    <div class="b-1 b-gray">
        <div class="">
            <img src="@/assets/images/recent-blog/blog3.jpg" alt="profile" class="img-fluid">
        </div>
        <div class="px-4 py-3">
            <p class="font-weight-bold fs-1" v-if="heading" v-html="heading"></p>
            <p class="mb-0 fs--1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa dolorem reiciendis, harum
                illo
                deseruntLorem ipsum dolor sit amet consectetur </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-4',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>
