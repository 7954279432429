<template>

    <div class="bs-4 h-100" data-aos="slide-up" data-aos-duration="500">
        <div class="px-4 py-4 text-center card-container">
            <p class="mb-3 font-weight-bold fs-1 text-primary" v-if="data.title" v-html="data.title"></p>
            <p class="mb-auto text-gray fs--1" v-if="data.desc" v-html="data.desc"></p>
            <hr class="bb-1 bb-1 w-100p">
            <a @click="OpenModal()" class="fs--1">View More <i class="fa fa-angle-double-right ml-1 fs-1"></i></a>
        </div>
    </div>

</template>

<script>
export default {
    name: 'component-16',
    props: {
        data: { type: Array }
    },
    methods: {
        OpenModal () {
            this.$emit('openModal');
        }
    }
};
</script>

<style scoped>

.mb-auto {
    margin-bottom: auto;
}

.card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}
</style>
