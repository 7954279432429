<template>
    <div class="container">
        <div class="row fl-x-cc mb-4">
            <div class="col-12 col-md-10 text-center ">
                <p class="fs-5 fs-lg-9 underline u-primary">Benifits of Cross Platform App</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-7 fl-x-cc">
                <ul class="bullet-1 text-gray">
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut hic nemo quasi voluptas voluptate!
                        Ab acdf
                    </li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elgdfgit. Aut hic nemo quas</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicinghfghg elit.dfgdf Aut hic nemo quasi voluptas
                        voluptate! Ab acdf
                    </li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing fgdfelit. Aut hic nemo quasi voluptas
                        voluptate! Ab acdf
                    </li>
                    <li>Lorem ipsum dolosdfr sit amet, consectetur adipisixcbcing elit. Aut hic nemo quasi voluptas
                        voluptate! Ab acdf
                    </li>
                </ul>
            </div>
            <div class="col-12 col-lg-5 fl-x-cc order-first order-lg-0 mb-4 mb-lg-0">
                <img src="../../assets/images/service-page/smm-img.png" alt="#" class="img-fluid">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Service-2'
};
</script>

<style scoped lang="scss">
.bullet-1 {
    list-style: none;
}

.bullet-1 li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 0.5rem;
}

.bullet-1 li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 7px;
    height: 10px;
    width: 10px;
    border: 2px solid var(--color-primary);
    border-radius: 50%;
}
</style>
