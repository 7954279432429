<template>
    <div class="position-relative our-works-bg">
        <div class="row bg-split">
            <div class="col-12 col-lg-6 h-100 c-bg-yellow"></div>
            <div class="col-12 col-lg-6 h-100 d-none d-lg-block"></div>
        </div>
        <div class="fl-x-c our-works container">
            <div class="row m-md-0">
                <div class="img-screen col-12 col-lg-6 fl-eq-c py-4">
                    <img :src="require('@/assets/images/our-works/' + img)" class="round-3" alt="">
                </div>
                <div class="img-container col-12 col-lg-6 fl-eq-c py-4">
                    <div>
                        <img :src="require('@/assets/images/clients/' + logo)" class="w-logo" alt="">
                        <p class="mt-3 mb-3 fs-3 fs-lg-6" v-if="heading || heading1">
                            <span :class="'text-' + heading1Color" v-if="heading1" v-html="heading1"
                                  class="font-weight-bold"></span>
                            <span v-if="heading1"> - </span> {{ heading }}
                        </p>
                        <p class="mt-3" v-if="desc" v-html="desc"></p>
                        <div class="fl-x-t">
                            <img src="@/assets/images/our-works/play-store.png" class="mt-3 img-play-store" alt="">
                            <img src="@/assets/images/our-works/app-store.png" class="mt-3 img-play-store" alt="">
                        </div>
                        <div class="fl-x-t img-icons mt-4 fs--1">
                            <p class="fl-x-c  mb-0">
                                <img src="@/assets/images/our-works/platform_white.png" alt=""
                                     class="pr-2 d-none d-md-block d-lg-none">
                                <img src="@/assets/images/our-works/platform.png" alt="" class="pr-2 d-md-none d-lg-block">
                                Android
                            </p>
                            <span class="divider"></span>
                            <p class="fl-x-c pl-3 mb-0">
                                <img src="@/assets/images/our-works/company_white.png" alt=""
                                     class="pr-2 d-none d-md-block d-lg-none">
                                <img src="@/assets/images/our-works/company.png" alt="" class="pr-2 d-md-none d-lg-block">
                                MatsApp
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurWorkComponent5',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        heading1: {
            type: String,
            required: false,
            default: ''
        },
        heading1Color: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        },
        img: {
            type: String,
            required: false,
            default: ''
        },
        logo: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped lang="scss">

.bg-split {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    margin: 0;
}

.c-yellow {
    color: rgb(255, 174, 0);
}

.c-bg-yellow {
    background-color: rgb(255, 174, 0);
}

.our-works-bg {
    background-color: #FFAE00FF;
}

@media (min-width: 768px) {

    .our-works-bg {
        background-color: #FFAE00FF;

        .our-works {
            .img-container {
                background: #FFAE00FF;
                color: #fff;
            }
        }
    }
}

@media (min-width: 992px) {

    .our-works-bg {
        background-color: transparent;

        .our-works {
            .img-container {
                background: transparent;
                color: black;
            }
        }
    }
}
</style>
