<template>

    <div class="row overflow-h">
        <div class="col-12 col-lg-4 mb-3">
            <Component5 img="mobile-01.jpg" heading="Fednet - Federal Bank"
                        details="Web Application Development"/>
        </div>
        <div class="col-12 col-lg-4 mb-3">
            <Component5 img="ecom-02.jpg" heading="Glocart - Ecommerce App"
                        details="Ecommerce Application Development"/>
        </div>
        <div class="col-12 col-lg-4 mb-3">
            <Component5 img="webdesign-03.jpg" heading="Esaf - Website Design"
                        details="Responsive Website Design"/>
        </div>
    </div>

</template>

<script>
import Component5 from './single-components/Component-5';

export default {
    name: 'Service-3',
    components: { Component5 }
};
</script>

<style scoped>

</style>
