<template>
    <div class="my-1 position-relative client-words">
        <swiper class="swiper pb-5" :options="swiperOption">
            <template v-for="i in items">
                <swiper-slide v-bind:key="i.id">
                    <Component13 :img="i.img" :name="i.name" :desg="i.desg" :date="i.date"
                                 :descheading="i.descheading" :desc="i.desc"/>
                </swiper-slide>
            </template>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>

        </swiper>
        <div class="swiper-button-p nav-btn prev-slide" slot="button-prev"></div>
        <div class="swiper-button-n nav-btn next-slide" slot="button-next"></div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import Component13 from './single-components/Component-13';

export default {
    name: 'Client-words-carousel',
    components: {
        Component13,
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            items: [
                {
                    img: '1.jpg',
                    name: 'Esaf Ngo',
                    desg: 'India',
                    date: '15 Jul 2021',
                    descheading: 'Great team to work',
                    desc: ' Xeoscript technologies is a great team to work with. They were capable of understanding and implementing our requirements.'
                },
                {
                    img: '2.jpg',
                    name: 'CEDAR',
                    desg: 'India',
                    date: '15 Jul 2021',
                    descheading: 'Establish a digital presence',
                    desc: 'Xeoscript technologies enabled us to establish a digital presence with the help of a well designed and responsive website which stands apart from the competition. '
                },
                {
                    img: '3.jpg',
                    name: 'Cyberfort',
                    desg: 'India',
                    date: '03 Sep 2021',
                    descheading: 'Requirements into effect',
                    desc: 'Xeoscript Technologies is an ideal group to establish a partnership with. They were capable of recognizing and implementing our requirements into effect'
                },
                {
                    img: '4.jpg',
                    name: 'Contract Ace',
                    desg: 'India',
                    date: '03 Sep 2021',
                    descheading: 'Great team to work with',
                    desc: 'Xeoscript technologies has a highly skilled and dedicated team. They are totally focused on customer satisfaction, easily accessible and a really great team to work with'
                },
                {
                    img: 'users.jpg',
                    name: 'Naaham',
                    desg: 'India',
                    date: '04 Sep 2021',
                    descheading: 'Timely response and new ideas',
                    desc: 'Heartiest thanks to xeoscript team for your immense support for designing my website. The digital marketing of my firm \' naaham \' also was excellent. Thank you xeoscript for your timely response  and new ideas in the field of website designing and digital marketing'
                },
                {
                    img: 'users.jpg',
                    name: 'Shirley Bronmen',
                    desg: 'Ireland',
                    date: '27 Jun 2019',
                    descheading: 'Outstanding developing company',
                    desc: 'This is the first time that we are working with a software developing company from India. But the services provided were outstanding as well as up to the expected mark, we are very much happy to work in the future as well in regards to our upcoming project with team Xeoscript'
                },
                {
                    img: 'users.jpg',
                    name: 'Jayesh Menon',
                    desg: 'Kottayam',
                    date: '26 Jun 2019',
                    descheading: 'Professional team of experts',
                    desc: 'The level of commitment and dedication is very much up to the mark, and they have a professional team of experts, who just don’t quit until top-level solutions are provided'
                },
                {
                    img: 'users.jpg',
                    name: 'Nandini Pillai',
                    desg: 'Ernakulam',
                    date: '25 Jun 2019',
                    descheading: 'Best development company',
                    desc: 'I feel really lucky to have chosen Xeoscript for my website designing as they have turned out to be the number one web development company not only in Thrissur but also in Kerala as well'
                }

            ],
            swiperOption: {
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-n',
                    prevEl: '.swiper-button-p'
                },
                centeredSlides: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false
                },
                speed: 1000,
                // autoplay: false,
                breakpoints: {
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }
            }
        };
    }
};
</script>

<style scoped>

@media (min-width: 992px) {

}

</style>
