import { render, staticRenderFns } from "./OurWorkComponent1.vue?vue&type=template&id=73677c83&scoped=true&"
import script from "./OurWorkComponent1.vue?vue&type=script&lang=js&"
export * from "./OurWorkComponent1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73677c83",
  null
  
)

export default component.exports