<template>
    <div class="row fl-x-tc">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-center ">
            <h3 class="mb-4 underline u-primary">About Company</h3>
            <p class="description">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias excepturi nihil
                dolor earum minus eo Lorem ipsum dolor sit, amet consectetur adipisicing elit. ?</p>
            <p class="sub-description">Commodi, quos sunt neque eius saepe dolorum doloribus laudantium, perspiciatis
                cum corporis nisi Sequi, quidem a minus quis deserunt quam, iure dolores asperiores, facilis accusamus
                autem odio labore? Maxime optio error illum mollitia exercitationem assumenda!</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Heading-main',
    props: {}
};
</script>

<style scoped>
.description {
    color: #9c9c9c;
}

.sub-description {
    color: #505050;
}
</style>
