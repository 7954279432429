<template>
    <div class="container">
        <div class="row fl-x-cc mb-4">
            <div class="col-12 col-md-10 text-center">
                <p class="fs-5 fs-lg-9">Benifits of Cross Platform App</p>
                <p class="mb-0 text-gray">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium eligendi esse ex fugiat ipsam
                    ipsum iusto officiis sapiente tempora voluptas. A adipisci, assumenda atque beatae facilis quae
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium eligendi esse ex fugiat ipsam
                    ipsum iusto officiis sapiente tempora voluptas. A adipisci, assumenda atque beatae facilis quae
                    sunt! Commodi, vitae.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-4 col-xl-3">
                <div class="row">
                    <div class="col-12">
                        <component-10/>
                    </div>
                    <div class="col-12">
                        <component-10/>
                    </div>
                    <div class="col-12">
                        <component-10/>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0">
                <img src="../../assets/images/service-page/img.png" alt="#" class="img-fluid">
            </div>
            <div class="col-12 col-lg-4 col-xl-3">
                <div class="row">
                    <div class="col-12">
                        <component-10/>
                    </div>
                    <div class="col-12">
                        <component-10/>
                    </div>
                    <div class="col-12">
                        <component-10/>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Component10 from './single-components/Component-10';

export default {
    name: 'service-1',
    components: { Component10 }
};
</script>

<style scoped>

</style>
