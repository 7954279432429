<template>
    <div class="b-1 b-gray h-100" data-aos="fade-up" data-aos-duration="500">
        <div class="">
            <img :src="require('@/assets/images/service-page/why-choose/' + img)" :alt="heading" class="img-fluid">
        </div>
        <div class="px-4 py-3 text-center">
            <h4 class="mb-3 font-weight-bold fs-1" v-if="heading" v-html="heading"></h4>
            <p class="mb-0 text-gray fs--1" v-if="desc" v-html="desc"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-7',
    props: {
        img: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>
