<template>
    <div class="container-fluid">
        <h3 class="mb-4 underline text-center u-primary">Input Fields</h3>
        <div class="row">
            <div class="col-lg-6 mb-4">
                <input type="text" class="form-control" placeholder="Your Name">
            </div>
            <div class="col-lg-6 mb-4 xeo-input-1 bg-black p-4">
                <input type="text" class="form-control" placeholder="Your Name">
            </div>
            <div class="col-lg-6 mb-4 xeo-input-2">
                <div class="form-group">
                    <input type="text" class="form-control" placeholder=" " autocomplete="off">
                    <span class="bar"></span>
                    <label>First Name </label>
                </div>
            </div>
            <div class="col-lg-6 mb-4">
                <label class="xeo-checkbox-1">Web Development
                    <input type="checkbox" checked="checked">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="">
                <label class="xeo-radio-1 ">One
                    <input type="radio" checked="checked" name="radio">
                    <span class="checkmark"></span>
                </label>
                <label class="xeo-radio-1">Two
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                </label>
                <label class="xeo-radio-1">Three
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                </label>
                <label class="xeo-radio-1">Four
                    <input type="radio" name="radio">
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Input-fields'
};
</script>

<style scoped>

</style>
