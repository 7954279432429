<template>
    <div class="web-app">
        <section>
            <About heading="Cross Platform Application Development"
                   desc="Cross-platform applications have opened new doors and provided the pliability of one code for all mantras and therefore have started to attract multinational companies throughout the world. "/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Cross-Platform Application Development Services</h3>
                        <p class="mb-0">Our team can understand and analyze distinctive requirements in the
                            said industry, design master plans and can produce the perfect results by anchoring the
                            strength of cross-platform technology. Our expertise and experience in the field are what
                            makes us stand out from the crowd. We also make sure to provide a high standard and classic
                            results as well to our valuable clients.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-cross-platforms/1.png"
                                             heading="Cross-Platform Apps amalgamated with Artificial Intelligence"
                                             desc="We blend artificial intelligence with our cross-platform application to amplify the features along with the end-user experience as well."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-cross-platforms/2.png"
                                             heading="Tailored Cross-Platform Apps"
                                             desc="We deliver and provide hybrid wearable app development services by incorporating sensors and cameras to furnish great user experience."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-cross-platforms/3.png"
                                             heading="Web-based Cross-Platform Apps"
                                             desc="When you don’t wish to burden your user by asking them to download an app, we can help you with a web-based app that has an international touch and class to it."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/mobile-app-cross-platforms/img.jpg" alt="#"
                             class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-cross-platforms/4.png"
                                             heading="Cross-Platform UI/UX design services"
                                             desc="The stunning user interface and  instant loading time is an added advantage. Being aware of the same , we can come up with applications that work best for you."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-cross-platforms/5.png"
                                             heading="Testing Services without giving up on quality Assurance"
                                             desc="We simply are not ready to compromise on the quality. Hence, we conduct regular checks so that no bug goes unnoticed or escapes our keen eye."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-cross-platforms/6.png"
                                             heading=" Support and Maintenance Services"
                                             desc="Our work doesn’t simply end by delivering our project, instead, we provide regular checkups and follow-ups. Stay rest assured as we have got you back."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="Excellent Cross-Platform Mobile apps to boost your business "
                   desc="Xeoscript has always remained enthusiastic, meticulous and taken the extra effort to invest our skills in forefront technologies. We can happily say that we have a designated and dedicated team that works only for the development of a cross-platform application to provide our valuable clients with the best  experience."/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Benefits of Cross Platform App Development"
                         headingdesc="Being the top cross platform app development company, we help your business benefit from this cutting-edge technology"/>

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component6 img="service-page/mobile-app-cross-platforms/flut.png" heading="Save Money"
                                    desc="This platform doesn’t require the coders to spend time on various tools & techniques. The codes here are usable & unique which helps in reducing costs of the overall application development."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component6 img="service-page/mobile-app-cross-platforms/react.png" heading="Enhance apps"
                                    desc="This technology is easier to implement and even easier to sync. Coders can easily write the code and utilize the resources for different platforms."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component6 img="service-page/mobile-app-cross-platforms/xzam.png"
                                    heading="Quick development time"
                                    desc="Since a single script is deployed the app development phase is much faster. WIth quick development time, your product will reach the market faster."/>
                    </div>
                </div>

            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading
                    heading="What makes Xeoscript the best Cross Platform Serviced  development company in Thrissur, Kerala."
                    headingdesc=" As the number one Cross Platform services development company in India, we ensure to have our team to keep up with the international standards."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="cross/why-1.jpg" heading="Exquisite"
                                    desc="The developers give in their entire expertise and hard work to come up with their innovative technology to achieve uniqueness. "/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="cross/why-2.jpg" heading="Result Driven"
                                    desc="The ace quality developers ensure that the result found after the development is completely client satisfied.Therefore we are one of the best options for Cross Platform services Development Company in Thrissur as well as in Kerala and the whole of India."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="cross/why-3.jpg" heading="Expertise of developers"
                                    desc="Our tenure team puts in the right amount of effort to achieve and gain a positive result and happily face all the challenges that come our way. "/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="cross/why-4.jpg" heading="Cheap and Best concept"
                                    desc="The client's financial outline is always taken into consideration; hence the work is done as per the same."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="cross/why-5.jpg" heading="Happy client"
                                    desc="The satisfaction of the client is something that we never compromise on. That is why every time there is a requirement for hiring an Cross Platform Services development company in Thrissur; our clients make sure that they recommend us."/>
                    </div>

                </div>
            </div>
        </section>

        <section>
            <GetAQuote/>
        </section>

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
import GetAQuote from '../components/GetAQuote';
import Component6 from '../components/single-components/Component-6';
import Component10 from '../components/single-components/Component-10';

export default {
    name: 'Cross-platform',
    metaInfo: {
        title: 'Mobile App Development Company in Thrissur, Kerala, India',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript is a top rated Mobile app development company in Thrissur, Kerala, India. We create interactive iOS, android and cross platform application.'
            },
            {
                name: 'keywords',
                content: 'mobile app development company in thrissur, ios development companies in thrissur, mobile app companies in thrissur, mobile app development services, android application company in thrissur, ios app development company'
            }
        ]
    },
    components: { Component10, Component6, GetAQuote, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>
