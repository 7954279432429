<template>

    <div class="bs-4 h-100" data-aos="slide-up" data-aos-duration="500">
        <div class="px-4 py-5 text-center">
            <p class="mb-3 font-weight-bold fs-1 text-primary" v-if="heading" v-html="heading"></p>
            <p class="mb-0 text-gray fs--1"  v-if="desc" v-html="desc"></p>
        </div>
    </div>

</template>

<script>
export default {
    name: 'component-8',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>
