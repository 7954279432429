<template>
    <div class="get-quote overflow-h">
        <div class="bg-fa position-relative">
            <div class="quote-img col-4 p-0 h-100">
                <img src="@/assets/images/getquotebgi.jpg" class="img-fluid h-100" alt="bg" data-aos="slide-up"
                     data-aos-duration="1000">
            </div>
            <div class="container min-h">
                <transition name="fade" mode="out-in">

                    <div class="text-center p-4 col-lg-6" v-if="state === 'S'" key="'S">
                        <div class="text-success">
                            <div class="text-center mb-4">
                                <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                            </div>
                            <div class="text-center">
                                <p class="fs-4">Thank you <strong>{{ name }}</strong> for contacting us</p>
                                We have received your enquiry and will respond to you within 24 hours. For urgent enquiries please call us on the telephone numbers provided.
                            </div>
                        </div>
                    </div>

                    <div class="text-center p-4 col-lg-6" v-else-if="state === 'F'" key="'F">
                        <div class="text-danger">
                            <div class="text-center mb-4">
                                <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                            </div>
                            <div class="text-center">
                                <p class="fs-4"><strong>OOPS!</strong></p>
                                Something has gone wrong, Please try again.
                            </div>
                        </div>
                    </div>

                    <div class="alert alert-info col-lg-6" v-else-if="state === 'L'" key="'L">
                        <div class=" justify-content-center align-items-center">
                            <div class="text-center p-4">
                                <loading-animation/>
                                <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with the
                                    server</p>
                            </div>
                        </div>
                    </div>

                    <s-form class="contact-form" v-else role="form" @submit="onSubmit" autocomplete="off">
                        <div class="row">
                            <div class="col-12 col-lg-4 py-5 text-center text-lg-left overflow-h" data-aos="slide-left"
                                 data-aos-duration="600">
                                <p class="font-weight-bold fs-3 mb-0">Let's start a project <br> together now . . . !
                                </p>
                                <p class="text-gray">Brief us your requirements below.</p>
                            </div>
                            <div class="col-12 col-lg-8">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <div class="requirements pb-5 p-lg-4 p-xl-5">
                                            <h4 class="text-center text-lg-left">You are Looking For</h4>
                                            <div class="mb-3 mt-4">
                                                <label class="xeo-checkbox-1">Software Development
                                                    <input type="checkbox" checked="checked" v-model="requirements"
                                                           value="Software Development" name="c4">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="mb-3">
                                                <label class="xeo-checkbox-1">Web Development
                                                    <input type="checkbox" checked="checked" v-model="requirements"
                                                           value="Web Development" name="c1">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="mb-3">
                                                <label class="xeo-checkbox-1">Mobile App Development
                                                    <input type="checkbox" checked="checked" v-model="requirements"
                                                           value="Mobile App Development" name="c2">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="mb-3">
                                                <label class="xeo-checkbox-1">Website Design
                                                    <input type="checkbox" checked="checked" v-model="requirements"
                                                           value="Website Design" name="c3">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="mb-3">
                                                <label class="xeo-checkbox-1">Digital Marketing
                                                    <input type="checkbox" checked="checked" v-model="requirements"
                                                           value="Digital Marketing" name="c5">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="mb-3">
                                                <label class="xeo-checkbox-1">E commerce Development
                                                    <input type="checkbox" checked="checked" v-model="requirements"
                                                           value="E commerce Development" name="c6">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 order-lg-first">
                                        <div class="contact-form pb-4 pb-lg-5 p-lg-4 p-xl-5 ">
                                            <div class=" mb-4">
                                                <validated-input name="Name" border-radius="0" v-model="name"
                                                                 :rules="rules.name1"
                                                                 placeholder="Your Name"></validated-input>

                                                <validated-input name="Email" type="email" border-radius="0"
                                                                 v-model="email"
                                                                 :rules="rules.email_id"
                                                                 placeholder="Email"></validated-input>

                                                <validated-mask-input name="Phone" v-model="phone"
                                                                      :rules="rules.phone_number"
                                                                      mask="##########"
                                                                      placeholder="Phone" border-radius="0"/>
                                                <validated-text-area placeholder="message" :rules="rules.message1"
                                                                     border-radius="0" v-model="message"
                                                                     name="message"/>
                                            </div>
                                            <!--                                    <div class=" mb-4 dis-mobile w-35">-->
                                            <!--                                        <vue-multi-select-->
                                            <!--                                            ref="multiSelect"-->
                                            <!--                                            v-model="requirements"-->
                                            <!--                                            :options="options"-->
                                            <!--                                            :btnLabel="btnLabel"-->
                                            <!--                                            :selectOptions="data">-->
                                            <!--                                            <template v-slot:option="{option}">-->
                                            <!--                                                <input type="checkbox" :checked="option.selected"/>-->
                                            <!--                                                <span class="pl-2">{{ option.name }}</span>-->
                                            <!--                                            </template>-->
                                            <!--                                        </vue-multi-select>-->
                                            <!--                                    </div>-->
                                            <div class="text-center text-lg-left">
                                                <button class="lego-btn xeo-btn-1 round-1 px-4">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--                            <div class="text-center text-lg-left d-lg-none col-12 mb-5">-->
                            <!--                                <button class="lego-btn xeo-btn-1 round-1 px-4">Submit</button>-->
                            <!--                            </div>-->
                        </div>
                    </s-form>
                </transition>
            </div>
        </div>
        <modal dialog title="Oops! You missed something" ref="alert" no-close-on-backdrop width="25r" :cancel-button="false" header-color="primary" ok-button-color="primary">
            Please select a service you are looking for ?
        </modal>
    </div>
</template>

<script>

import axios from 'axios';
import LoadingAnimation from './LoadingAnimation';

// import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';

export default {
    name: 'GetAQuote',
    props: {},
    components: {
        LoadingAnimation
        // vueMultiSelect
    },
    data () {
        return {
            state: 'D',
            name: '',
            requirements: [],
            phone: '',
            email: '',
            message: '',
            btnLabel: values => 'You are Looking For',
            data: ['Web Development', 'Mobile App Development', 'Website Design', 'Software Development', 'Digital Marketing', 'E commerce Development'],
            options: {
                multi: true
            },
            rules: {
                name1: {
                    required: true
                },
                email_id: {
                    required: true
                },
                phone_number: {
                    required: true,
                    min: 10,
                    max: 10
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        async onSubmit () {
            const checked = this.valthisform();
            if (!checked) {
                return;
            }

            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('phone', this.phone);
            bodyFormData.append('message', this.message);
            bodyFormData.append('requirements', this.requirements);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://xeoscript.com/get-quote-mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        },
        valthisform () {
            const chkd = this.requirements.length > 0;
            if (chkd === false) {
                this.$refs.alert.show();
                return false;
            } else {
                return true;
            }
        }
    }
};
</script>

<style scoped>

/*.get-quote {*/
/*    padding-left: 5rem;*/
/*    padding-right: 5rem;*/
/*    justify-content: center;*/
/*}*/
.quote-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}

.quote-img img {
    width: 100%;
}

.dis-mobile {
    display: none;
}

/*.quote-img,*/
/*.contact-form,*/
/*.requirements {*/
/*    width: 33%;*/
/*    height: 100%;*/
/*}*/

.bg-fa {
    background-color: #fafafa;
}

/*.w-35 {*/
/*    width: 35%;*/
/*}*/

.requirements .xeo-checkbox-1 {
    font-size: 14px;
}

.requirements .xeo-checkbox-1 .checkmark:after {
    left: 7px;
    top: 3px;
    width: 6px;
    height: 11px;
}

.requirements .xeo-checkbox-1 .checkmark {
    height: 20px;
    width: 20px;
}

.mb-form p {
    font-size: 0.8rem;
}

@media screen and (max-width: 992px) {
    .get-quote {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .dis-mobile {
        display: inline-block;
    }

    .get-quote .fl-x-t {
        flex-direction: column;
    }

    .w-35 {
        width: 100%;
    }

    .quote-img {
        display: none;
    }

    .quote-img,
    .contact-form,
    .requirements {
        width: 100%;
    }
}
</style>
<style>
/*.btn-select {*/
/*    text-align: left !important;;*/
/*    padding: var(--form-control-padding-y) var(--form-control-padding-x) !important;*/
/*    min-height: calc(var(--form-control-line-height) + ((var(--form-control-padding-y) + var(--form-control-border-width)) * 2)) !important;*/
/*    border: var(--form-control-border-width) solid var(--form-control-border-color) !important;*/
/*    background-clip: padding-box !important;*/
/*    line-height: var(--form-control-line-height) !important;*/
/*    transition: all 300ms;*/
/*    border-radius: var(--border-radius-sm) !important;*/
/*    font-size: var(--form-control-font-size) !important;*/
/*    color: var(--form-control-color, var(--body-text-color)) !important;*/
/*    background-color: var(--form-control-bg-color, var(--body-bg-color)) !important;*/
/*}*/

/*.buttonLabel {*/
/*    color: var(--form-control-color, var(--body-text-color));*/
/*}*/

.select > button,
.select {
    width: 100%;
}

.selectList {
    padding-left: 1em !important;
}

.select .caret {
    position: absolute;
    right: 1em;
    top: 1.4em;
}

.min-h {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
