<template>
    <div class="web-app">
        <section>
            <About heading="Mobile Application Development"
                   desc="Are you in search of futuristic yet pocket-friendly mobile app solutions for your business? Look no further and reach out to us for the best mobile applications."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Perks of Mobile App development for your business</h3>
                        <p class="mb-0">The Advantages of high-performing mobile applications for your
                            business are plenty. Why do we say that? Well, a myriad of reasons, but chiefly because a
                            great mobile app will resonate your business and enhance your operation. Let’s explore more
                            reasons below</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app/1.png" heading="Enhances efficiency"
                                             desc="A customised mobile app will keep all your business operations streamlined and performing efficiently."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app/2.png" heading="Gives more value to customer"
                                             desc="Promote your business ideas and services with your customer and enhance awareness."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app/3.png" heading="Build a strong brand reputation"
                                             desc="With an app for your business, your audiences will foster their trust in your business."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/mobile-app/img.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app/4.png" heading="Enhance customer engagement"
                                             desc="Apps will enhance the customer interaction as they will be able to access your business 24/7."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app/5.png" heading="Boosts sales"
                                             desc="Better customer engagement means better customer satisfaction which enhances your sales."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app/6.png" heading="Increased Retention Rate"
                                             desc="Increase your ability in retaining the customers by showcasing the offers and key selling points available at your disposal."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading=" Delivering Strong Mobile Presence to Your Business"
                   desc="Be it online classes, shopping, entertainment, games or anything, mobile technology is one such disruptive innovation that its roots held deep into our daily lives. Here’s where we proudly enter. We are the best-reviewed mobile app development service company in Kerala, India with the sole aim to provide our customers with top-notch service in the industry."/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Our Spectrum of Mobile App Development Services"
                         headingdesc="We are all well aware that the core functionality of a handheld device depends on these small tiny icons otherwise known as apps.  Mobile app demands have increased tremendously over the years for the right reasons."/>

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="service-page/mobile-app/feature02.png" heading="Android App Development"
                                    desc="Android applications have been the king of all apps, as it covers almost 80 per cent of all mobile phone users in the entire world, thus providing a larger ground to cover. With our expert app developers, we will be able to assist you to take your business into new heights."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="service-page/mobile-app/feature01.png" heading="iOS Application"
                                    desc="Our coding experts are efficient and have hands-on experience with the most updated and recent technologies to work with, be it Objective-C or Swift cocoa. Our distinctive approach alone is the definition of perfection for your app, making us a well-renowned mobile app development agency in Kerala."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="service-page/mobile-app/feature03.png" heading="Cross Platform Application"
                                    desc="Our team can understand and analyze distinctive requirements of your cross platform application needs. We will develop a master plan that can produce the perfect results by anchoring the strength of cross-platform technology"/>
                    </div>
                </div>

            </div>
        </section>

        <section class="py-5 bg-gray-1">
            <div class="container">
                <heading heading="Our Recent Mobile Application"
                         headingdesc="We brew up your dream project with our hard work, dedication, and years of expertise. We can always go the extra mile to see you satisfied."/>

                <div class="row">
                    <!--                    <div class="col-12 col-lg-4 mb-3">-->
                    <!--                        <Component5 img="mobile-01.jpg" heading="Fednet - Federal Bank"-->
                    <!--                                    details="Mobile Application Development"/>-->
                    <!--                    </div>-->
                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="mobile-02.jpg" heading="Easywin - Mobile Application"
                                    details="Mobile Application Development"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="mobile-03.jpg" heading="Vetconnect - Mobile Application"
                                    details="Mobile Application Development"/>
                    </div>
                </div>

                <div class="row fl-x-cc mt-5">
                    <div class="col-8 col-lg-3">
                        <router-link to="/our-works/" class="lego-btn xeo-btn-2 btn-block">View All Works</router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading
                    heading="What makes Xeoscript the best Mobile app development company in Thrissur, Kerala, India?"
                    headingdesc="Here are some other main USP’s that give us an extra edge as the providers of supreme mobile app development services in Thrissur:"/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="mob-app/why-1.jpg" heading="On-time delivery"
                                    desc="We have a long-time reputation of never missing a deadline. We adhere to the given project timelines so that you get much-needed peace of mind in your life."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="mob-app/why-2.jpg" heading="Quality Assurance"
                                    desc="We never fail to implement best practices and methodologies to ensure that you get top-notch quality and efficiency."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="mob-app/why-3.jpg" heading="Team of Experts"
                                    desc="Our team of website designers and developers are passionate, hard-working, and dedicated to giving each client a personalized approach. "/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="mob-app/why-4.jpg" heading="Affordable prices"
                                    desc="We use the tried and tested strategies to give you the web design that suits your business as well as your pockets."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="mob-app/why-5.jpg" heading="Years of Expertise"
                                    desc="We have years of expertise in working with various clients across the globe helping us in widening our international exposure."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="mob-app/why-6.jpg" heading="After-sales support"
                                    desc="To develop long-term client relations, our team will provide you with their relentless support even after project delivery."/>
                    </div>

                </div>
            </div>
        </section>

        <section>
            <GetAQuote/>
        </section>

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
import GetAQuote from '../components/GetAQuote';
import Component10 from '../components/single-components/Component-10';
import Component5 from '../components/single-components/Component-5';

export default {
    name: 'Mob-app',
    metaInfo: {
        title: 'Mobile App Development Company in Thrissur, Kerala, India',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript is a top rated Mobile app development company in Thrissur, Kerala, India. We create interactive iOS, android and cross platform application.'
            },
            {
                name: 'keywords',
                content: 'mobile app development company in thrissur, ios development companies in thrissur, mobile app companies in thrissur, mobile app development services, android application company in thrissur, ios app development company'
            }
        ]
    },
    components: { Component5, Component10, GetAQuote, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>
