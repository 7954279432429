<template>
    <div class="col-6 col-lg-3  p-4 text-center">
        <div class="text-white">
            <img :src="require('@/assets/images/awards/' + img)
                    " alt="profile" class="profile-img mb-2">
            <p ref="p" class="font-weight-bold mb-0 text-2x" v-if="count">
                {{ counter }} +
            </p>

            <p class="font-weight-bold mb-0" v-if="heading" v-html="heading"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-12',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        count: {
            type: [Number, String],
            required: false,
            default: 0
        },
        img: {
            type: String,
            required: false,
            default: ''
        },
        from: {
            type: [Number, String],
            default: 0
        },
        to: {
            type: [Number, String],
            required: true,
            default: 0
        },
        format: {
            type: Function,
            default: (num) => parseInt(num)
        },
        duration: {
            type: Number,
            default: 1 // Duration of animation in seconds
        },
        easing: {
            type: String,
            default: 'Power1.easeOut'
        },
        delay: {
            type: Number,
            default: 0 // Delay the animation in seconds
        }
    },
    data () {
        return {
            observer: null,
            counter: 0
        };
    },

    mounted () {
        const observer = new IntersectionObserver(this.handleIntersection);
        observer.observe(this.$refs.p);
        this.observer = observer;
    },

    // destroyed () {
    //     this.observer.destroy();
    // },

    methods: {
        handleIntersection (entries) {
            if (this.counter > 0) {
                return;
            }
            const duration = 500;
            if (entries[0].isIntersecting) {
                const that = this;
                const count = parseInt(that.count, 10);
                const i = setInterval(function () {
                    that.counter += 1;
                    if (that.counter === count) {
                        clearInterval(i);
                    }
                }, duration / count);
            }
        }
    }
};
</script>

<style scoped>

.profile-img {
    width: 60px;
}

</style>
