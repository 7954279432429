<template>
    <Box #default="{hover}" class="b-1 b-gray component-1 h-100 d-block" :link="link" radius="0" padding="3" data-aos="fade-up">
        <div class="fl-te-c ">
            <img :src="require('@/assets/images/services/' + img)" :class="{'translateRight': hover}" class="trn-slow" alt="img">
            <img src="@/assets/images/recent-blog/view-more.png" class="arrow" alt="arrow">
        </div>
        <div class="mt-3">
            <h2 class="fs-3 trn" v-if="heading" v-html="heading" :class="{'text-primary': hover}"></h2>
            <p class="fs--1 mt-3 text-gray mb-0" v-if="desc" v-html="desc">Big Data Analysis is known as the process of examining large content..</p>
        </div>
    </Box>
<!--      <Box #default="{hover}" class="b-1 b-gray component-1 h-100 d-block" :link="link" radius="0" padding="3" data-aos="fade-up">-->
<!--        <div class="fl-te-c ">-->
<!--            <img :src="require('@/assets/images/services/' + img)" :class="{'translateLeft': hover}" class="trn-slow" alt="img">-->
<!--            <img src="@/assets/images/recent-blog/view-more.png" class="arrow trn" alt="arrow" :class="{translateUp: hover}">-->
<!--        </div>-->
<!--        <div class="mt-3">-->
<!--            <p class="fs-3 trn" v-if="heading" v-html="heading" :class="{'translateRight text-success': hover}"></p>-->
<!--            <p class="fs&#45;&#45;1 mt-3 text-gray mb-0" v-if="desc" v-html="desc">Big Data Analysis is known as the process of examining large content..</p>-->
<!--        </div>-->
<!--    </Box>-->
</template>

<script>
import Box from 'lego-framework/src/containers/Box';

export default {
    name: 'HomeServiceComponent',
    components: {
        Box
    },
    props: {
        link: {
            type: String,
            required: false,
            default: ''
        },
        img: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>
/*.component-1 .card {*/
/*    border: 1px solid #e3e3e3;*/
/*    margin-top: 2em;*/

/*}*/

.component-1 img {
    height: 45px;
}

.component-1 .arrow {
    height: 15px;
}

/*.component-1 p {*/
/*    color: #8a8e90;*/
/*}*/

</style>
