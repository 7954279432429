<template>
    <div class="about">

        <section>
            <About heading="About Our Company"
                   desc="We are a bunch of hard-working, passionate and talented group, who promise to catalyze change and deliver efficient as well as diligent service to our valuable clients."/>
        </section>

        <section class="pb-5 py-lg-5">
            <div class="container">
                <AboutDesc/>
            </div>
        </section>

        <section>
            <OurJourney/>
        </section>

        <section class="py-5">
            <div class="container">
                <Heading heading="Our Core Values"/>
                <div class="row">
                    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                        <Component15 img="01.png" heading="Unyielding Integrity"
                                     desc="We hold on to strong moral principles and therefore can deliver top-notch solutions to the commitments that we have made to our client at all costs."/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                        <Component15 img="02.png" heading="Pragmatism"
                                     desc="We make sure to leave no stone unturned by achieving the highest level of perfection and customer satisfaction for all our projects."/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3 mb-lg-0">
                        <Component15 img="03.png" heading="Commitment to client"
                                     desc="For us, the Customer is the King and we make sure that by the end of the day as well as throughout the project, the client is satisfied with all our solutions."/>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <Heading heading="Our Clients"/>
                <ClientCarousel/>
            </div>
        </section>

    </div>
</template>
<script>
import About from './components/About-comp';
import AboutDesc from './components/About-desc';
import OurJourney from './components/OurJourney';
import Component15 from './components/single-components/Component-15';
import Heading from './components/Heading';
import ClientCarousel from './components/Client-carousel';

export default {
    name: 'Aboutus',
    metaInfo: {
        title: 'Web development Company in Thrissur | Xeoscript Technologies',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript provides website development, e-commerce development, software development, web application, mobile app development, Digital marketing services.'
            },
            {
                name: 'keywords',
                content: 'web development services in thrissur, mobile app companies in thrissur, best software company in thrissur, digital marketing in thrissur'
            }
        ]
    },
    components: { ClientCarousel, Heading, Component15, OurJourney, AboutDesc, About }
};
</script>
