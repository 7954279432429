<template>
    <div class="web-app">
        <section>
            <About heading="Android Mobile App Development"
                   desc="Our unique approach in the android app development processes is bound to overcome the weaknesses of basic java applications and make our android applications stronger as well as faster."/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Android Application Development Services</h3>
                        <p class="mb-0">Our developers ensure that they leave no stone unturned when it comes
                            to delivering top-notch android app development service. We are a team of reliable android
                            application developers as we stand out by taking the right steps to help you in achieving
                            your business goals.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-androids/1.png" heading="UI/UX for android"
                                             desc="Our highly talented designers ensure that they don’t compromise on lucidity and clarity which are one of the important aspects when it comes to attracting users."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-androids/2.png" heading="Customization of android apps"
                                             desc="We deliver android app development services at super-fast speed that are tailor-made to bring in a remarkable change in your business performance."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="mobile-app-androids/3.png" heading="Android App Consulting"
                                             desc="We have been striving to resolve your concerns, whatsoever it may be and provide a complete solution for the same for all your Android app needs."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in" data-aos-duration="500">
                        <img src="../../assets/images/service-page/mobile-app-androids/img.png" alt="#"
                             class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-androids/4.png" heading="Android Web-App"
                                             desc="The smooth transition to your work desk can be expected with the Android web-app because this easy-to-use web app will make your work better organized."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-androids/5.png" heading="Support and Maintenance "
                                             desc=" Our team also makes sure that support services are provided from time to time to keep the android application updated."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="mobile-app-androids/6.png" heading="Android App testing"
                                             desc="We deliver uncompromised testing solutions for android applications; every measure is taken to put in stringent quality standardization."/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="Excellent Android apps to boost your business"
                   desc="Android application development indeed revolves around Java, but this alone wouldn’t take you places. This is where we enter as the best Android app development company in Thrissur.  Our mobile apps are flawless and ensure the consistency of speed across all devices."/>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="Our Technology Integration"
                         headingdesc="We integrate various technologies to deliver the best suitable application that exponentially enhances the business productivity."/>

                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Beacon Integration"
                                    desc="Create low-cost Beacon enables mobile apps. Give your users the advantage of real-time technology with enhanced proximity and location experience."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="GPS Integration"
                                    desc="Give your navigation business an extra edge with the finest location-based solutions with highly reliable GPS tracking modules."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Custom API Integration"
                                    desc="Enable third-party functionality in your application. Customize your new or existing products with custom API integration."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Wearable Integration"
                                    desc="We create impeccable wearable devices apps that offer your customers a better and more personalized experience."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Audio - Video Live Integration"
                                    desc="Impressive video audio integration tool that makes your device capable of using real-time video chat."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component8 heading="Porting and Migration"
                                    desc="Migrate your app to the mobile platform of your choice with our best-in-class mobile app porting services"/>
                    </div>
                </div>

            </div>
        </section>

        <section class="py-5">
            <div class="container">
                <heading heading="What makes Xeoscript the best Android App development company in Thrissur, Kerala?"
                         headingdesc="Being the best Android App development company in Thrissur, we ensure our clients get the best apps for their business that meet beyond their expectations."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="android/why-1.jpg" heading="One-stop-shop"
                                    desc="For all your software and applications needs, stay rest assured and rely confidently on Xeoscript Technologies. "/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="android/why-2.jpg" heading="Tailored to your needs"
                                    desc="Being the best android app development company in Thrissur, our best team of developers ensures that you receive carved out-of-the-box applications and powerful customized solutions."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="android/why-3.jpg" heading="Stalwarts of experts"
                                    desc="We have a team of android developers who work with perfection and passion to develop products that suit our clients business."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="android/why-4.jpg" heading="Value of money"
                                    desc="We don't want our clients to feel burdened with hefty prices. We also consider our client’s budget constraints before quoting a price."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="android/why-5.jpg" heading="Client-centric"
                                    desc="One of the main factors that attribute to our reputation as a well-renowned android app development company in Thrissur is our “client comes first” attitude."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="android/why-6.jpg" heading="Global exposure"
                                    desc="Having worked with numerous clients from across the globe such as the USA, UK, Australia, Canada, etc, we are proud of our huge international exposure."/>
                    </div>

                </div>
            </div>
        </section>

        <section>
            <GetAQuote/>
        </section>

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
import GetAQuote from '../components/GetAQuote';
import Component10 from '../components/single-components/Component-10';
import Component8 from '../components/single-components/Component-8';

export default {
    name: 'Android',
    metaInfo: {
        title: 'Mobile App Development Company in Thrissur, Kerala, India',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Xeoscript is a top rated Mobile app development company in Thrissur, Kerala, India. We create interactive iOS, android and cross platform application.'
            },
            {
                name: 'keywords',
                content: 'mobile app development company in thrissur, ios development companies in thrissur, mobile app companies in thrissur, mobile app development services, android application company in thrissur, ios app development company'
            }
        ]
    },
    components: { Component8, Component10, GetAQuote, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>
