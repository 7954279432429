<template>
    <div class="component-3">
        <img src="@/assets/images/recent-blog/blog3.jpg" alt="profile" class="profile-img">
        <div class="body-section p-4">
            <h4 v-if="heading" v-html="heading"></h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa dolorem reiciendis, harum illo
                deseruntLorem ipsum dolor sit amet consectetur adipisicing elit. Culpa dolorem reiciendis, harum illo
                deserun</p>

            <div class="fl-te-c">
                <p v-if="date" v-html="date" class="date"></p>
                <p class="fl-x-c">View More <img src="@/assets/images/recent-blog/view-more.png" class="view-more"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-3',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        date: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>
.component-3 .card {
    border: 1px solid #e3e3e3;
    margin-top: 2em;

}

.profile-img {
    width: 100%;
}

.body-section {
    border: 1px solid #e3e3e3;
    font-size: 13px;
    color: #747474;
}

.body-section h4 {
    margin-top: 0.2em;
    font-weight: 600;
    color: #000;
}

.view-more {
    height: 1em;
    padding-left: 0.4em;
}
</style>
