<template>
    <div class=" fl-x-tc mb-4">
        <div class="col-lg-7 col-md-12 col-sm-12 col-xs-12 text-center " data-aos="fade-in" data-aos-duration="600">
            <h3 v-if="heading" v-html="heading" class="mb-4 underline u-primary" ></h3>
            <p v-if="headingdesc" v-html="headingdesc" class="description" ></p>
            <p v-if="headingsubdesc" v-html="headingsubdesc" class="sub-description fs--1"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Heading',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        },
        headingdesc: {
            type: String,
            required: false,
            default: ''
        },
        headingsubdesc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>
.description {
    color: #9c9c9c;
}

.sub-description {
    color: #505050;
}
</style>
