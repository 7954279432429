<template>
    <div>
        <CustomButton/>

        <InputFields/>

        <div class="row">
            <div class="col">
                <h3 class="mb-4 underline text-center u-primary">Cards</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 mb-4">
                <Component1 heading="Website Design"/>
            </div>
            <div class="col-lg-4 mb-4">
                <Component2 name="John Wick" desg="Head Of Coffee Days" date="25 Jun 2019"/>
            </div>
            <div class="col-lg-4 mb-4">
                <Component3 date="29 Jun 2021" heading="Flutter: The Future"/>
            </div>
            <div class="col-lg-4 mb-4">
                <Component4 heading="Dedicated and Talented People"/>
            </div>
            <div class="col-lg-4 mb-4">
                <Component5 img="3.jpg" heading="Cyberfort - Website Design" details="Responsive Website Design"/>
            </div>
            <div class="col-lg-4 mb-4">
                <Component6 img="html.png" heading="Flutter App Development"
                            desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliascommodi consequatur cupiditate distinctio, dolores ducimus enim excepturi expedita modi nihi"/>
            </div>
            <div class="col-lg-4 mb-4">
                <Component7 img="choose-us/why01.jpg" heading="Absolute Requirement Review"
                            desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab alias commodi consequatur cupiditate distinctio, dolores ducimus enim excepturi expedita modi nihi"/>
            </div>
            <div class="col-lg-4 mb-4">
                <Component8 heading="Flutter App Development1"
                            desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab aliascommodi consequatur cupiditate distinctio, dolores ducimus enim excepturi expedita modi nihi"/>
            </div>
            <div class="col-lg-4 mb-4">
                <Component9 heading="Our Services"/>
            </div>
            <div class="col-lg-4">
                <Component10 img="1.png" heading="Lorem ipsum dolor sit amet"
                             desc="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium eligendi esse ex fugiat ipsam."/>
            </div>
            <div class="col-lg-4">
                <Component11/>
            </div>

        </div>

        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Banner Types</h3>
        </div>

        <div class="mt-5 row">
            <h3>Type1</h3>
            <div class="col-12 p-0">
                <Homebanner/>
            </div>
        </div>

        <div class="row mt-5">
            <h3>Type2</h3>
            <Counter/>
        </div>

        <div class=" mt-5">
            <h3>Type3</h3>
            <About heading="About Our Company " desc="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias excepturi nihil
                dolor earum minus eo Lorem ipsum dolor sit, amet consectetur adipisicing elit. ?"/>
        </div>
        <div class=" mt-5">
            <h3>Type4</h3>
            <OurJourney/>
        </div>
        <div class=" mt-5">
            <h3>Type5</h3>
             <Quote heading="A Great Mobile App Leads to Great Business"
                   desc="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias excepturi nihil dolor earum minus eo Lorem ipsum dolor sit, amet consectetur adipisicing elit. ?"/>
        </div>
        <div class=" mt-5">
            <h3>Type6</h3>
            <ClientCarousel/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Main Nav</h3>
        </div>
        <div class="row mt-5">
            <MainHeader/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Sub Nav</h3>
        </div>
        <div class="row mt-5">
            <SubNav/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Heading Component</h3>
        </div>
        <div class=" mt-5">
            <HeadingMain/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Footer Component</h3>
        </div>
        <div class=" mt-5">
            <Footer/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Client words Component</h3>
        </div>
        <div class=" mt-5">
            <ClientWordsCarousel/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Service 1</h3>
        </div>
        <div class=" mt-5">
            <Service1/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Service 2</h3>
        </div>
        <div class=" mt-5">
            <Service2/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Service 3</h3>
        </div>
        <div class=" mt-5">
            <Service3/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">ContactUs Component</h3>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">Get Quote Component</h3>
        </div>
        <div class=" mt-5">
            <GetAQuote/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">our works Type 1</h3>
        </div>
        <div class=" mt-5">
            <OurWorkComponent1/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">our works Type 2</h3>
        </div>
        <div class=" mt-5">
            <OurWorkComponent2/>
        </div>
        <div class="col mt-5">
            <h3 class="mb-4 underline text-center u-primary">our works Type 3</h3>
        </div>
        <div class=" mt-5">
            <ContactUs/>
        </div>
    </div>
</template>

<script>
import Component11 from './components/single-components/Component-11';
import Component10 from './components/single-components/Component-10';
import Component9 from './components/single-components/Component-9';
import Component8 from './components/single-components/Component-8';
import Component7 from './components/single-components/Component-7';
import Component6 from './components/single-components/Component-6';
import Component5 from './components/single-components/Component-5';
import Component4 from './components/single-components/Component-4';
import CustomButton from './components/single-components/Custom-Buttons';
import Component1 from './components/single-components/HomeServiceComponent';
import Component2 from './components/single-components/Component-2';
import Component3 from './components/single-components/Component-3';
import InputFields from './components/single-components/Input-fields';
import MainHeader from './components/MainHeader.vue';
import SubNav from './components/SubNav.vue';
import Counter from './components/Counter.vue';
import Footer from './components/Footer.vue';
import About from './components/About-comp.vue';
import OurJourney from './components/OurJourney.vue';
import ContactUs from './components/ContactUs.vue';
import Quote from './components/Quote.vue';
import GetAQuote from './components/GetAQuote.vue';
import Homebanner from './components/Homebanner';
import ClientCarousel from './components/Client-carousel';
import ClientWordsCarousel from './components/Client-words-carousel';
import Service1 from './components/Service-1';
import Service2 from './components/Service-2';
import HeadingMain from './components/single-components/Heading-Main';
import Service3 from './components/Service-3';
import OurWorkComponent1 from './components/OurWorkComponent1';
import OurWorkComponent2 from './components/OurWorkComponent2';

export default {
    name: 'components',
    components: {
        Service3,
        HeadingMain,
        Service2,
        Service1,
        ClientWordsCarousel,
        ClientCarousel,
        Homebanner,
        Component11,
        Component10,
        Component9,
        Component8,
        Component7,
        Component6,
        Component5,
        Component4,
        CustomButton,
        Component1,
        Component2,
        Component3,
        InputFields,
        MainHeader,
        SubNav,
        Counter,
        Footer,
        About,
        OurJourney,
        ContactUs,
        Quote,
        GetAQuote,
        OurWorkComponent1,
        OurWorkComponent2
    }

};
</script>

<style scoped>

</style>
