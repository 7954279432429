<template>
    <div class="p-4 bg-card bg-fit-c">
        <div class="fl-x-cc text-white">
            <img src="../../../assets/images/awards/3.png" alt="profile" class="profile-img mb-2">
            <p class="font-weight-bold mb-0 text-2x">250 +</p>
            <p class="font-weight-bold mb-0">Projects Completed</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-1',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

.profile-img {
    width: 60px;
}

</style>
