<template>

    <div class="b-1 b-gray px-4 py-3 h-100" data-aos="fade-up" data-aos-duration="500">
        <div class="text-center mb-3">
            <img :src="require('@/assets/images/' + img)" alt="profile"
                 class="img-fluid profile-img">
        </div>
        <div class="text-center">
            <p class="mb-3 font-weight-bold text-primary fs-1" v-if="heading" v-html="heading"></p>
            <p class="mb-0 text-gray fs--1" v-if="desc" v-html="desc"></p>
        </div>
    </div>

</template>

<script>
export default {
    name: 'component-6',
    props: {
        img: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

.profile-img {
    width: 80px;
}

</style>
