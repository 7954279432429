<template>
    <div class="contact-us container">
        <div class="fl-eq bg-white bor-r-1 row m-0 px-lg-3">
            <div class="col-12 col-lg-4 contact-details p-4 bs-29" data-aos="flip-left" data-aos-duration="1000">
                <h5 class="mt-2 font-weight-bold">Contact Information</h5>
                <p class="team-desc">Fill up the form, Our team will contact you in 24 hrs</p>
                <div class="mt-2">
                    <div class="fl-x-c mt-4">
                        <img src="@/assets/images/call.png" class="address-icon" alt="#">
                        <p class="pl-2 m-0"><a class="mouseover-simple" href="tel:+91 7994000666">+91 7994000666</a></p>
                    </div>
                    <div class="fl-x-c mt-4">
                        <img src="@/assets/images/mail.png" class="address-icon" alt="#">
                        <p class="pl-2 m-0"><a target="_blank" href="mailto:info@xeoscript.com"
                                               class="mouseover-simple">info@xeoscript.com</a>
                        </p>
                    </div>
                    <div class="fl-x-t mt-4">
                        <img src="@/assets/images/location.png" class="address-icon" alt="#">
                        <div>
                            <p class="pl-2 m-0">Xeoscript Technologies</p>
                            <p class="pl-2 m-0">First Floor, Kalarikkal Building,</p>
                            <p class="pl-2 m-0">Mannuthy ByPass, Mannuthy P O</p>
                            <p class="pl-2 m-0">Thrissur, Kerala, India</p>
                            <p class="pl-2 m-0">PIN: 680 651</p>
                        </div>
                    </div>
                    <div class="fl-x-c mt-4">
                        <img src="@/assets/images/location.png" class="address-icon" alt="#">
                        <p class="pl-2 mb-0">
                            <a target="_blank"
                               href="https://www.google.com/maps/place/Xeoscript+Technologies/@10.5306645,76.2554481,17z/data=!3m1!4b1!4m5!3m4!1s0x3ba7ef85a79a8d9f:0x863b98ff3045461e!8m2!3d10.5306592!4d76.2576368"
                               class="mouseover-simple">
                                Get Location
                            </a>
                        </p>
                    </div>
                </div>
                <div class="mt-4 mt-lg-auto">
                    <ul class="fl-x-c social-media mt-2">
                        <a target="_blank" href="https://www.facebook.com/xeoscript/">
                            <img src="@/assets/images/header-socialmedia/fb.png" class="social-icon">
                        </a>
                        <!--                        <a target="_blank" href="https://twitter.com/xeoscript">-->
                        <!--                            <img src="@/assets/images/header-socialmedia/twitt.png" class="social-icon">-->
                        <!--                        </a>-->
                        <a target="_blank" href="https://www.linkedin.com/company/xeoscript-technologies/">
                            <img src="@/assets/images/header-socialmedia/linkedin.png" class="social-icon">
                        </a>
                        <a target="_blank" href=" https://www.instagram.com/xeoscript/">
                            <img src="@/assets/images/header-socialmedia/insta.png" class="social-icon">
                        </a>
                        <!--                        <a target="_blank" href="">-->
                        <!--                            <img src="@/assets/images/header-socialmedia/utube.png" class="social-icon">-->
                        <!--                        </a>-->
                        <!--                        <a target="_blank" href="">-->
                        <!--                            <img src="@/assets/images/header-socialmedia/beh.png" class="social-icon">-->
                        <!--                        </a>-->
                    </ul>
                </div>
                <div class="plant-img">
                    <img src="@/assets/images/plant.png" class="" alt="">
                </div>

            </div>
            <div class="col-12 col-lg-8 contact-form p-4 bs-4" data-aos="fade-up" data-aos-duration="500">
                <transition name="fade" mode="out-in">
                    <div class="text-center p-4 col-lg-10" v-if="state === 'S'" key="'S">
                        <div class="text-success">
                            <div class="text-center mb-4">
                                <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                            </div>
                            <div class="text-center">
                                <p class="fs-4">Thank you <strong>{{ firstname }}</strong> for contacting us</p>
                                We have received your enquiry and will respond to you within 24 hours. For urgent
                                enquiries please call us on the telephone numbers provided.
                            </div>
                        </div>
                    </div>

                    <div class="text-center p-4 col-lg-10" v-else-if="state === 'F'" key="'F">
                        <div class="text-danger">
                            <div class="text-center mb-4">
                                <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                            </div>
                            <div class="text-center">
                                <p class="fs-4"><strong>OOPS!</strong></p>
                                Something has gone wrong, Please try again.
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-info" v-else-if="state === 'L'" key="'L">
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="text-center p-4">
                                <loading-animation/>
                                <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with the
                                    server</p>
                            </div>
                        </div>
                    </div>
                    <s-form class="contact-form row" v-else role="form" @submit="onSubmit" autocomplete="off">
                        <div class="col-12 col-lg-6">
                            <validated-input name="First Name" v-model="firstname" shadow="0"
                                             placeholder="" label="First Name" :rules="rules.first_name"
                                             design="floating-top" required/>
                        </div>
                        <div class="col-12 col-lg-6">
                            <validated-input name="Last Name" v-model="lastname" shadow="0"
                                             placeholder="" label="Last Name" :rules="rules.last_name"
                                             design="floating-top" required/>
                        </div>
                        <div class="col-12 col-lg-6">
                            <validated-input type="email" name="Email" v-model="email" shadow="0"
                                             placeholder="" label="Email" :rules="rules.email_id"
                                             design="floating-top"/>
                        </div>
                        <div class="col-12 col-lg-6">
                            <validated-mask-input name="Phone" v-model="phone" shadow="0"
                                                  mask="##########" placeholder="" label="Phone"
                                                  :rules="rules.phone_number" design="floating-top"/>
                        </div>
                        <div class="col-12 mt-4">
                            <div class="mb-2 d-flex ">
                                <p class="color-dark">What Type of requirement do you need ?</p>
                            </div>
                            <div class=" mb-4 row">
                                <div class="col-12 col-lg-6">
                                    <label for="c1" class="xeo-checkbox-2">Web Development
                                        <input type="checkbox" v-model="requirement" value="Web Development" id="c1"
                                               name="c1">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <label for="c2" class="xeo-checkbox-2">Mobile Apps
                                        <input type="checkbox" v-model="requirement" value="Mobile Apps" id="c2"
                                               name="c2">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <label for="c3" class="xeo-checkbox-2">Website Design
                                        <input type="checkbox" v-model="requirement" value="Website Design" id="c3"
                                               name="c3">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <label for="c4" class="xeo-checkbox-2">Software Development
                                        <input type="checkbox" v-model="requirement" value="Software Development"
                                               id="c4" name="c4">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <label for="c5" class="xeo-checkbox-2">Digital Marketing
                                        <input type="checkbox" v-model="requirement" value="Digital Marketing" id="c5"
                                               name="c5">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <label for="c6" class="xeo-checkbox-2">E commerce Development
                                        <input type="checkbox" v-model="requirement" value="E commerce Development"
                                               id="c6" name="c6">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="mt-1">
                                <div class=" mb-4">
                                    <validated-text-area label="Message" v-model="message" :rules="rules.message1"
                                                         shadow="0"
                                                         design="floating-top"/>
                                </div>
                                <div class="mt-5 text-right">
                                    <button class="lego-btn w-50 bg-primary round-1 btn-block" type="submit">
                                        Send Message
                                    </button>
                                </div>
                            </div>
                        </div>
                    </s-form>
                </transition>
            </div>
        </div>
        <modal dialog title="Oops! You missed something" ref="alert" no-close-on-backdrop width="30r"
               :cancel-button="false" header-color="primary" ok-button-color="primary">
            Please select a requirement you are looking for ?
        </modal>
    </div>
</template>

<script>

import axios from 'axios';
import LoadingAnimation from './LoadingAnimation';

export default {
    name: 'ContactUs',
    props: {},
    components: { LoadingAnimation },
    data () {
        return {
            state: 'D',
            firstname: '',
            lastname: '',
            // requirement: '',
            requirement: [],
            phone: '',
            email: '',
            message: '',
            rules: {
                first_name: {
                    required: true
                },
                last_name: {
                    required: true
                },
                email_id: {
                    required: true
                },
                phone_number: {
                    required: true,
                    min: 10,
                    max: 10
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        onSubmit () {
            const checked = this.valthisform();
            if (!checked) {
                return;
            }

            const bodyFormData = new FormData();
            bodyFormData.append('firstname', this.firstname);
            bodyFormData.append('lastname', this.lastname);
            bodyFormData.append('email', this.email);
            bodyFormData.append('phone', this.phone);
            bodyFormData.append('message', this.message);
            bodyFormData.append('requirement', this.requirement);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                url: 'https://xeoscript.com/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        },
        valthisform () {
            const chkd = this.requirement.length > 0;
            if (chkd === false) {
                this.$refs.alert.show();
                return false;
            } else {
                return true;
            }
        }
    }
};
</script>
<style>

</style>

<style scoped>

.contact-details {
    color: #fff;
    background-color: var(--color-primary);
    border-radius: 0.75rem;
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.plant-img {
    position: absolute;
    bottom: 1em;
    right: 1.4em;
}

.contact-us {
    padding-left: 5rem;
    padding-right: 5rem;
    justify-content: center;
}

.contact-details img {
    width: 15px;
    /*height: 15px;*/
}

.plant-img img {
    width: 51px;
    height: auto;
}

.color-dark {
    color: #46484b;
}

.social-media .social-icon,
.social-media-mobile .social-icon {

    /*height: 15px;*/
    width: 25px;
}

.contact-us p {
    font-size: 0.9rem;
}

.mouseover-simple:hover,
.mouseover-simple:active {
    color: #fff;
}

.bor-r-1 {
    border-radius: 0.5em;
}

.social-media a:not(:first-child),
.social-media-mobile a:not(:first-child) {
    padding-left: 0.5em;
}

.contact-form {
    background-color: #fff;
    margin-top: -1rem;
    padding-top: 1.5rem;
    border-radius: .75rem;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 600px;
}

.xeo-radio-1 {
    margin-left: 0.5em;
    font-size: 13px;
    color: #46484b;
}

.xeo-input-2 label {
    font-weight: 600;
    font-size: 0.9em;
}

.xeo-radio-1 input:checked {
    font-weight: 600;
}

.xeo-radio-1 .checkmark:after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 10px;
}

.xeo-radio-1 .checkmark {
    width: 20px;
    height: 20px;
}

.team-desc {
    color: cornsilk;
}

.w-50 {
    width: 35%;
}

@media screen and (min-width: 992px) {
    .contact-form {
        background-color: #fff;
        border-radius: 0 .75rem .75rem 0;
        margin-left: -10px;
        padding-left: 10px;
        margin-top: 0;
        padding-top: 0;
        min-height: 500px;
    }

}

@media screen and (max-width: 992px) {
    .contact-us .bg-white {
        flex-direction: column;
    }

    .contact-us {
        padding-left: 1em;
        padding-right: 1em;
    }

    .w-50 {
        width: 100%;
    }

}

@media screen and (max-width: 768px) {
    .contact-details {
        width: 100%;
    }
}
</style>
