<template>
    <div class="container-fluid mt-3">
        <h3 class="mb-4 underline text-center u-primary">Buttons</h3>
        <div class="row">
            <div class="col-lg-3 mb-4">
                <button class="lego-btn xeo-btn-1 btn-block">Know More</button>
            </div>
            <div class="col-lg-3 mb-4">
                <button class="lego-btn xeo-btn-1 round-1 btn-block">Know More</button>
            </div>
            <div class="col-lg-3 mb-4">
                <button class="lego-btn xeo-btn-2 btn-block">Know More</button>
            </div>
            <div class="col-lg-3 mb-4">
                <button class="lego-btn xeo-btn-3 round-1 btn-block">Know More</button>
            </div>
            <div class="col-lg-3 mb-4">
                <button class="lego-btn xeo-btn-4 round-1 btn-block">
                    <small>
                        Know More
                    </small>
                </button>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'custom-button'
};
</script>

<style scoped>

</style>
