<template>

    <Box class="b-1 b-gray h-100" radius="0" #default="{hover}" padding="0" data-aos="fade-up" data-aos-duration="500">
        <div class="" style="overflow: hidden">
            <img :src="require('@/assets/images/about-us/' + img)" :class="{'scaleUp': hover}" alt="profile" class="img-fluid trn">
        </div>
        <div class="px-4 py-3">
            <p class="font-weight-bold fs-1" v-if="heading" v-html="heading"></p>
            <p class="mb-0 fs--1" v-if="desc" v-html="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Culpa dolorem reiciendis,
                harum illo deseruntLorem ipsum dolor sit amet consectetur </p>
        </div>
    </Box>

<!--    <Box class="b-1 b-gray h-100" radius="0" #default="{hover}" padding="0" hover-transform="translateUp">-->
<!--        <div class="" style="overflow: hidden">-->
<!--            <img :src="require('@/assets/images/about-us/' + img)" :class="{'scaleUp': hover}" alt="profile" class="img-fluid trn">-->
<!--        </div>-->
<!--        <div class="px-4 py-3">-->
<!--            <p class="font-weight-bold fs-1" v-if="heading" v-html="heading"></p>-->
<!--            <p class="mb-0 fs&#45;&#45;1" v-if="desc" v-html="desc">Lorem ipsum dolor sit amet consectetur adipisicing elit.-->
<!--                Culpa dolorem reiciendis,-->
<!--                harum illo deseruntLorem ipsum dolor sit amet consectetur </p>-->
<!--        </div>-->
<!--    </Box>-->

</template>

<script>
export default {
    name: 'component-15',
    props: {
        img: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>
