<template>
    <div class="bg-card bg-fit-c arrow-left fl-x-cc h-100 overflow-h" >
        <div class="px-4 py-5 text-center text-white" data-aos="fade-up">
            <p class="mb-3 fs-9" v-if="heading" v-html="heading"></p>
            <p class="mb-0 underline u-white">Experience dedication, passion and excellence through our top-notch
                services.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-9',
    props: {
        heading: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

</style>
