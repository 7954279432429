import { render, staticRenderFns } from "./Component-5.vue?vue&type=template&id=ec584ba2&scoped=true&"
import script from "./Component-5.vue?vue&type=script&lang=js&"
export * from "./Component-5.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec584ba2",
  null
  
)

export default component.exports