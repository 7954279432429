<template>
    <div class="get-quote mt-5">
        <heading heading="Contact Us" headingdesc="Thank you for showing your interest in us."/>
        <section class="pb-5">
            <ContactUs/>
        </section>
        <section class="py-5 mt-1 bg-gray-1">
            <div class="container">
                <heading heading="Our Latest Works" headingdesc="Take a look at our recent accomplished projects."/>

                <Service3/>

                <div class="row fl-x-cc mt-4">
                    <div class="col-8 col-lg-3">
                        <router-link to="/our-works/" class="lego-btn xeo-btn-2 btn-block">View All Works</router-link>
                    </div>
                </div>
            </div>
        </section>

        <section class="pb-5 bg-gray-1">
            <div class="container">
                <ClientCarousel/>
            </div>
        </section>
    </div>
</template>

<script>
import ContactUs from './components/ContactUs';
import Heading from './components/Heading';
import Service3 from './components/Service-3';
import ClientCarousel from './components/Client-carousel';

export default {
    name: 'Contactus',
    metaInfo: {
        title: 'Mobile App and Web development Company in Thrissur, Kerala',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Planning on building a large scale, custom-made and high-performing software solution for your business? Look no further as we at Xeoscript Technologies should be able to do the job for you.'
            },
            {
                name: 'keywords',
                content: 'software company in thrissur, it companies in thrissur, web designing company in thrissur, mobile app development company in thrissur,digital marketing services in thrissur'
            }
        ]
    },
    components: {
        ContactUs,
        Service3,
        ClientCarousel,
        Heading
    },
    data () {
        return {};
    }
};

</script>
