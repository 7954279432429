<template>
    <div class="web-app">
        <section>
            <About heading="Web Application Development"
                   desc="Web application is the new phenomenon that is rocking the World Wide Web in leaps and bounds. Having a web application in place for your business is definitely going to transform your business and take it to a new and better level"/>
        </section>

        <section class="py-5">
            <div class="container">
                <div class="row fl-x-cc mb-4">
                    <div class="col-12 col-md-10 text-center">
                        <h3 class="fs-4 fs-lg-8 mb-4">Benefits of a Custom Web Application</h3>
                        <p class="mb-0">All the web applications that we have developed and generated have
                            been portraying extremely outstanding performance both business to business as well as to
                            business to consumer level making sure that all the business challenges are met.</p>
                    </div>
                </div>
                <div class="row overflow-h">
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="web-app/1.png"
                                             heading="Tailor-made Web App Development"
                                             desc="24/7 support without any compromise in keeping the high standards quality in mind. Testing, support, frontend, and backend are maintained and handled smoothly."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="web-app/2.png"
                                             heading="E-commerce web app development"
                                             desc="Try integrating your business with our e-commerce web application for online sales and other services and note the vast difference it brings into your business. "/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-right" data-aos-duration="500" img="web-app/3.png"
                                             heading="CMS Web Application"
                                             desc="With the help of CMS web applications, managing the content on your web page becomes smooth, efficient, and streamlined with the business."/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 col-xl-6 fl-x-cc order-first order-lg-0 my-4 my-lg-0" data-aos="zoom-in"
                         data-aos-duration="500">
                        <img src="../../assets/images/service-page/web-app/img.png" alt="#" class="img-fluid">
                    </div>
                    <div class="col-12 col-lg-4 col-xl-3">
                        <div class="row">
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="web-app/4.png"
                                             heading="Re-engineering Web-App"
                                             desc="Reconfiguring and re-engineering the web app that fits perfectly with all the competitive web apps in the new generation platform."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="web-app/5.png"
                                             heading="Consulting service for Web App"
                                             desc=" Xeoscript team is well prepared to consult with our valuable clients with web applications that will meet the criteria of an international level web application."/>
                            </div>
                            <div class="col-12">
                                <Component10 data-aos="fade-left" data-aos-duration="500" img="web-app/6.png"
                                             heading="Receptive Web App Designs"
                                             desc="Attractive and receptive web app designs are proven factors to increase user experience as well as accentuate user convenience."/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <section class="overflow-h">
            <Quote heading="Right Web App for Your Industry"
                   desc="It doesn’t matter if your requirement for a web App is of a single web page or a complex and tailor-made web application, we at Xeoscript have the right solutions for you. Our tenure and experienced team can configure and develop a web-based application that allows the clients business to cherish and prosper at a faster and great rate"/>
        </section>

        <section class="py-5 bg-gray-1">
            <div class="container">
                <heading heading="Our Recent Web application works include"
                         headingdesc="We brew up your dream application with our hard work, dedication, and years of expertise. We can always go the extra mile to see you satisfied."/>

                <div class="row">
                    <div class="col-12 col-lg-4 mb-3">
                        <component5 img="webapps-01.jpg" heading="Fednet - Web Application"
                                    details="Web Application Development"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="webapps-03.jpg" heading="Contract Ace - Web Application"
                                    details="Web Application Development"/>
                    </div>
                    <div class="col-12 col-lg-4 mb-3">
                        <Component5 img="webapps-04.png" heading="Reache - Web Application"
                                    details="Web Application Development"/>
                    </div>
                </div>

                <div class="row fl-x-cc mt-5">
                    <div class="col-8 col-lg-3">
                        <router-link to="/our-works/" class="lego-btn xeo-btn-2 btn-block">View All Works</router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="py-5">
            <div class="container">
                <heading heading="What makes Xeoscript the best Web App development company in Thrissur, Kerala?"
                         headingdesc="This tag of being the number one Web App development company in India has been earned with lots of smart work and dedication. At any cost. our team will be prepared to keep up with the international standards."/>
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="web-app/why-1.jpg" heading="On-time Project delivery"
                                    desc="We adhere to the given project timelines so that you get much-needed peace of mind in your life."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="web-app/why-2.jpg" heading="Assured quality"
                                    desc="We never fail to implement best practices and methodologies to ensure that you get top-notch quality and efficiency."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="web-app/why-3.jpg" heading="Expertise of developers"
                                    desc="Our team of web app developers is passionate, hard-working, and dedicated to giving each client a personalized approach."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="web-app/why-4.jpg" heading="Cost-effective rates"
                                    desc="We use the tried and tested strategies to give you the web app that suits your business as well as your pockets."/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="web-app/why-5.jpg" heading="Full-cycle services"
                                    desc="We cover all your requirements, from designing to coding, testing to maintenance, making us the well-reviewed web app development company in Thrissur"/>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 mb-3">
                        <Component7 img="web-app/why-6.jpg" heading="Transparency is the Key"
                                    desc="Our team will provide 100% transparency and confidentiality. All our services are aimed to deliver solutions that are industry-ready and work seamlessly."/>
                    </div>

                </div>
            </div>
        </section>

        <section>
            <GetAQuote/>
        </section>

    </div>
</template>

<script>
import About from '../components/About-comp';
import Quote from '../components/Quote';
import Heading from '../components/Heading';
import Component7 from '../components/single-components/Component-7';
import GetAQuote from '../components/GetAQuote';
import Component10 from '../components/single-components/Component-10';
import Component5 from '../components/single-components/Component-5';

export default {
    name: 'Web-app',
    metaInfo: {
        title: 'Web application development company in thrissur, kerala,India',
        titleTemplate: '%s - Xeoscript Technologies',
        htmlAttrs: {
            lang: 'en',
            amp: true
        },
        meta: [
            {
                name: 'description',
                content: 'Planning to build web-based software? Contact Xeoscript, a Web application development company in Thrissur, Kerala. We can help you to develop your application.'
            },
            {
                name: 'keywords',
                content: 'web application development company in thrissur, web application development services, web app development services'
            }
        ]
    },
    components: { Component5, Component10, GetAQuote, Component7, Heading, Quote, About }
};
</script>

<style scoped>

</style>
