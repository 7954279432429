<template>
    <div class="container">
        <div class="our-clients pt-4 pb-4">
            <div class="py-3 py-lg-5">
                <swiper class="swiper" :options="swiperOption">
<!--                    <swiper-slide>-->
<!--                        <div class="our-clients-img">-->
<!--                            <img class="img-fluid" alt="#" src="../../assets/images/clients/01.png">-->
<!--                        </div>-->
<!--                    </swiper-slide>-->
                    <swiper-slide>
                        <div class="our-clients-img">
                            <img class="img-fluid" alt="#" src="../../assets/images/clients/02.png">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="our-clients-img">
                            <img class="img-fluid" alt="#" src="../../assets/images/clients/03.png">
                        </div>
                    </swiper-slide>
                    <swiper-slide>
                        <div class="our-clients-img">
                            <img class="img-fluid" alt="#" src="../../assets/images/clients/04.png">
                        </div>
                    </swiper-slide>

                    <swiper-slide>
                        <div class="our-clients-img">
                            <img class="img-fluid" alt="#" src="../../assets/images/clients/05.png">
                        </div>
                    </swiper-slide>
<!--                    <swiper-slide>-->
<!--                        <div class="our-clients-img">-->
<!--                            <img class="img-fluid" alt="#" src="../../assets/images/clients/06.png">-->
<!--                        </div>-->
<!--                    </swiper-slide>-->

                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
    name: 'Client-carousel',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                centeredSlides: false,
                autoplay: {
                    delay: 4500,
                    disableOnInteraction: false
                },
                // autoplay: false,
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 0
                    },
                    // 768: {
                    //     slidesPerView: 3,
                    //     spaceBetween: 0
                    // },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    },
                    375: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    }
                }
            }
        };
    }
};
</script>

<style scoped>
.our-clients-img img {
    padding: 0 1.4rem;
}

.our-clients-img {
    text-align: center;
}

.our-clients-img:after {
    content: "";
    height: 50%;
    width: 1px;
    background: #c0c0c0;
    position: absolute;
    right: -1px;
    top: 50%;
    transform: translate(0, -50%);
}

.our-clients-img:before {
    content: "";
    height: 50%;
    width: 1px;
    background: #c0c0c0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.our-clients-img img {
    -webkit-filter: grayscale(1);
    -webkit-transition: all .2s ease-in-out;
    opacity: 0.5;
}

.our-clients-img:hover img {
    filter: unset;
    -webkit-filter: grayscale(0);
    opacity: 1;
}

@media (min-width: 992px) {

    .our-clients-img img {
        padding: 0 2rem;
    }
}

</style>
