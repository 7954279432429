<template>
    <div class="col-12  mt-3 h-100">
        <div class="b-1 b-gray p-4 h-100">
            <div class="fl-x">
                <img v-if="img" :src="require('@/assets/images/client-words/' + img)" alt="profile" class="profile-img">
                <div class="pl-3">
                    <p class="font-weight-bold fs-2 mb-1" v-if="name" v-html="name"></p>
                    <p class="font-weight-bold text-gray mb-0" v-if="desg" v-html="desg"></p>
                </div>
            </div>
            <div class="pt-3">
                <p v-if="date" v-html="date" class="text-gray mb-1"></p>
                <p v-if="descheading" v-html="descheading" class="font-weight-bold fs-1 mb-2"></p>
                <p v-if="desc" v-html="desc" class="text-gray fs--1 mb-0"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'component-13',
    props: {
        img: {
            type: String,
            required: false,
            default: ''
        },
        name: {
            type: String,
            required: false,
            default: ''
        },
        desg: {
            type: String,
            required: false,
            default: ''
        },
        date: {
            type: String,
            required: false,
            default: ''
        },
        descheading: {
            type: String,
            required: false,
            default: ''
        },
        desc: {
            type: String,
            required: false,
            default: ''
        }
    }
};
</script>

<style scoped>

.profile-img {
    width: 55px;
    border-radius: 50%;
    height: 100%;
}

/*@media (max-width: 375px) {*/
/*    .min-height {*/
/*        min-height: 385px;*/
/*    }*/
/*}*/

/*.min-height {*/
/*    min-height: 360px;*/
/*}*/

/*@media (min-width: 768px) {*/

/*}*/

/*@media (min-width: 1024px) {*/
/*    .min-height {*/
/*        min-height: 386px;*/
/*    }*/
/*}*/

/*@media (min-width: 1200px) {*/
/*    .min-height {*/
/*        min-height: 340px;*/
/*    }*/
/*}*/

</style>
