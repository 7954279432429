<template>
    <div class="fl-x-tc our-journey bg-fit-c py-5">
        <div class="container text-white">
            <h3 class="mb-5 underline u-primary text-center">How did it all begin?</h3>
            <div class="row">
                <p class="text-justify col-12 col-lg-6">Our founders strongly believe that dreams are meant to be
                    chased. In the year of 2010, a passionate group of professional software developers set out with a
                    strategic plan to conquer the world with their expertise and knowledge.
                    We found that numerous business owners were unaware of the essentiality of going digital. Or the
                    ones who were aware never found a reliable partner who could cost-effectively meet their
                </p>
                <p class="text-justify col-12 col-lg-6"> needs. That’s where we stepped in. To begin with, we consulted
                    with investors and analysts aiming at strengthening our drive at providing an innovative solution to
                    our clientele. Xeoscript Technologies was established with a primary goal to generate sustainable
                    digital solutions for our clients across the globe.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurJourney',
    props: {}
};
</script>

<style scoped>

/*.description {*/
/*    color: #9c9c9c;*/
/*    text-align: left;*/
/*}*/

/*.underline::after {*/
/*    left: 60%;*/
/*}*/

.underline {
    padding-bottom: 10px;
}

/*.our-journey h3 {*/
/*    color: ivory;*/
/*}*/

.our-journey {
    background-image: url('../../assets/images/about-us/black-bgis.jpg');
}
</style>
